import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Search, MoreHorizontal, Copy, Download, Trash2, Edit2, Clock, Grid, List, ChevronDown, Tag, Share2, DollarSign, CreditCard, X, FileText } from 'lucide-react';
import { format, isValid, parseISO, formatDistanceToNow } from 'date-fns';
import { useGlobalContext } from '../contexts/GlobalContext';
import { isMobile } from 'react-device-detect';
import { useTheme } from '../hooks/useTheme';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { db } from '../Services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import InvoiceGenerator from './InvoiceGenerator';
import PaymentTracker from './payment/PaymentTracker';
import DownloadPDF from './DownloadPDF';
import DownloadPrintablePDF from './DownloadPrintablePDF';

const Files = ({ onEditQuotation, refreshTrigger }) => {
  const { quotations, setQuotations } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFileMenu, setActiveFileMenu] = useState(null);
  const [sortOption, setSortOption] = useState('updatedDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const menuRef = useRef(null);
  const { deleteQuotation, updateQuotation, addQuotation, generateShareableLink } = useFirebaseService();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState('');
  const filesContainerRef = useRef(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [showPaymentTracker, setShowPaymentTracker] = useState(false);

  const getUniqueKey = (quote, index) => `${quote.id || quote._id || index}-${quote.clientName}`;

  const sortQuotations = (quotes) => {
    return quotes.sort((a, b) => {
      let aValue = a[sortOption];
      let bValue = b[sortOption];
      
      if (sortOption === 'totalBudget') {
        aValue = parseFloat(aValue) || 0;
        bValue = parseFloat(bValue) || 0;
      } else if (sortOption === 'updatedDate' || sortOption === 'createdDate') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredQuotes = sortQuotations(
    quotations.filter((quote) => 
      quote.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.totalBudget?.toString().includes(searchTerm) ||
      quote.tags?.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveFileMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      if (filesContainerRef.current) {
        filesContainerRef.current.scrollTop = 0;
      }
    };

    scrollToTop();

    // Use a short timeout to ensure the DOM has updated
    const timeoutId = setTimeout(scrollToTop, 100);

    return () => clearTimeout(timeoutId);
  }, [quotations, refreshTrigger]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
    return isValid(date) ? format(date, 'dd MMM yyyy, hh:mm a') : 'Invalid Date';
  };

  const formatRelativeTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
    return isValid(date) ? formatDistanceToNow(date, { addSuffix: true }) : 'Invalid Date';
  };

  const handleDeleteQuote = async (quote) => {
    try {
      if (window.confirm('Are you sure you want to delete this quotation?')) {
        const quoteId = quote.id || quote._id;
        if (!quoteId) {
          throw new Error('Quotation ID is missing');
        }
        await deleteQuotation(quoteId);
        setActiveFileMenu(null);
        setQuotations(prevQuotations => prevQuotations.filter(q => (q.id || q._id) !== quoteId));
      }
    } catch (err) {
      console.error('Error deleting quotation:', err);
      alert(`Error deleting quotation: ${err.message}`);
    }
  };

  const handleDuplicateQuote = async (quote) => {
    setIsLoading(true);
    try {
      const baseClientName = quote.clientName.replace(/\s*\(V\d+\)$/, '').trim();
      const existingVersions = quotations
        .filter(q => q.clientName.startsWith(baseClientName))
        .map(q => q.version || 1);
      const newVersion = Math.max(...existingVersions, 0) + 1;

      const newQuote = {
        ...JSON.parse(JSON.stringify(quote)),
        clientName: `${baseClientName} (V${newVersion})`,
        version: newVersion,
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
        propertyType: quote.propertyType || 'N/A'
      };

      delete newQuote.id;
      delete newQuote._id;

      const addedQuote = await addQuotation(newQuote);
      setActiveFileMenu(null);
      
      // Instead of updating the local state, we'll let the Firestore subscription handle the update
      // setQuotations(prevQuotations => [...prevQuotations, addedQuote]);
      
      return addedQuote;
    } catch (err) {
      console.error('Error duplicating quotation:', err);
      alert(`Error duplicating quotation: ${err.message || 'Please try again later.'}`);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePropertyTypeChange = async (quoteId, newPropertyType) => {
    try {
      console.log('Updating property type:', { quoteId, newPropertyType });
      const quote = quotations.find(q => q.id === quoteId || q._id === quoteId);
      if (quote) {
        console.log('Found quote:', quote);
        const updatedQuote = await updateQuotation(quoteId, { propertyType: newPropertyType });
        console.log('Updated quote:', updatedQuote);
        setQuotations(prevQuotations => prevQuotations.map(q => 
          (q.id === quoteId || q._id === quoteId) ? { ...q, propertyType: newPropertyType } : q
        ));
        console.log('Quotations updated');
      } else {
        console.error('Quote not found:', quoteId);
        alert('Failed to update property type: Quote not found');
      }
    } catch (error) {
      console.error('Error updating property type:', error);
      alert(`Failed to update property type: ${error.message}`);
    }
  };

  const EditableDropdown = ({ value, options, onChange, className, buttonClassName, quoteId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const handleSelect = (option) => {
      onChange(quoteId, option);
      setIsOpen(false);
    };
  
    return (
      <div className={`relative ${className}`} ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`flex items-center justify-between px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded hover:bg-blue-200 transition-colors duration-200 ${buttonClassName}`}
        >
          {value || 'Select Property Type'}
          <ChevronDown size={12} className={`ml-1 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
        </button>
        {isOpen && (
          <ul className="absolute z-10 mt-1 w-40 bg-white border border-gray-200 rounded-md shadow-lg max-h-48 overflow-auto">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className={`px-3 py-2 text-sm hover:bg-blue-50 cursor-pointer ${option === value ? 'bg-blue-100 text-blue-800' : 'text-gray-700'}`}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderSortOptions = () => (
    <div className="mb-4 flex items-center">
      <span className="mr-2 text-sm font-medium">Sort by:</span>
      <select
        value={sortOption}
        onChange={(e) => setSortOption(e.target.value)}
        className="mr-2 p-1 border rounded"
      >
        <option value="updatedDate">Last Updated</option>
        <option value="createdDate">Created Date</option>
        <option value="clientName">Client Name</option>
        <option value="totalBudget">Total Budget</option>
      </select>
      <button
        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
        className="p-1 bg-gray-200 rounded"
      >
        <ChevronDown
          size={16}
          className={`transform transition-transform ${
            sortOrder === 'desc' ? 'rotate-180' : ''
          }`}
        />
      </button>
    </div>
  );

  const handleShareEditableFile = async (quote) => {
    try {
      const link = await generateShareableLink(quote);
      
      if (navigator.share && isMobile) {
        // Use Web Share API for mobile devices
        await navigator.share({
          title: `Editable Quotation for ${quote.clientName}`,
          text: 'Check out this editable quotation',
          url: link
        });
      } else {
        // Fallback for desktop or devices without Web Share API
        await navigator.clipboard.writeText(link);
        alert('Shareable link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing file:', error);
      alert('Failed to share. Please try again.');
    }
  };

  const handleEditQuotation = async (quote) => {
    setIsLoadingFile(true);
    try {
      onEditQuotation(quote);
    } catch (error) {
      console.error('Error loading quotation for editing:', error);
      // Optionally show an error message to the user
    } finally {
      setIsLoadingFile(false);
    }
    setActiveFileMenu(null);
  };

  const handleViewPaymentTracker = async (quote) => {
    try {
      setIsLoading(true);
      // Get fresh data from Firebase
      const quotationRef = doc(db, 'quotations', quote.id);
      const docSnap = await getDoc(quotationRef);
      
      if (docSnap.exists()) {
        const freshData = docSnap.data();
        // Update the quotations array with fresh data
        setQuotations(prevQuotations => 
          prevQuotations.map(q => 
            q.id === quote.id 
              ? {
                  ...q,
                  payments: freshData.payments || [],
                  totalPaid: freshData.totalPaid || 0,
                  remainingAmount: freshData.remainingAmount || quote.grandTotal,
                  paymentTerms: freshData.paymentTerms || [],
                  grandTotal: freshData.grandTotal || quote.grandTotal
                }
              : q
          )
        );
        
        setSelectedQuote({
          ...quote,
          payments: freshData.payments || [],
          totalPaid: freshData.totalPaid || 0,
          remainingAmount: freshData.remainingAmount || quote.grandTotal,
          paymentTerms: freshData.paymentTerms || [],
          grandTotal: freshData.grandTotal || quote.grandTotal
        });
      } else {
        setSelectedQuote({
          ...quote,
          payments: [],
          totalPaid: 0,
          remainingAmount: quote.grandTotal,
          paymentTerms: quote.paymentTerms || []
        });
      }
      setShowPaymentTracker(true);
    } catch (error) {
      console.error('Error loading payment data:', error);
      alert('Error loading payment information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewInvoice = (quote) => {
    console.log('Opening invoice for quote:', quote);
    if (!quote.id) {
      console.error('Quote ID is missing:', quote);
      return;
    }
    setSelectedQuote(quote);
    setShowInvoiceGenerator(true);
  };

  const renderQuoteCard = (quote, index) => {
    console.log('Rendering quote card:', quote);
    const isMenuOpen = activeFileMenu === getUniqueKey(quote, index);
    return (
      <div 
        key={getUniqueKey(quote, index)} 
        className={`bg-white p-3 rounded-lg shadow-md relative transition-all duration-200 ${
          isMenuOpen ? 'ring-2 ring-blue-500 shadow-lg' : ''
        }`}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center space-x-2">
            <span className="font-medium">{quote.clientName.replace(/\s*\(V\d+\)$/, '')}</span>
            {quote.version && <span className="text-xs text-gray-500">(V{quote.version})</span>}
            <EditableDropdown
              value={quote.propertyType || 'N/A'}
              options={['1BHK', '2BHK', '3BHK', '4BHK', 'Villa', 'Individual House', 'Office/Commercial Space', 'N/A']}
              onChange={handlePropertyTypeChange}
              quoteId={quote.id || quote._id}
            />
          </div>
          <span className="text-sm font-semibold text-gray-700">
            ₹ {Math.round(quote.totalBudget)?.toLocaleString('en-IN') || 'N/A'}
          </span>
        </div>
        <div className="flex justify-between items-center text-xs text-gray-500">
          <div>
            <div className="flex items-center">
              <Clock size={12} className="mr-1" />
              <span>Created: {formatDate(quote.createdDate)}</span>
            </div>
            <div className="flex items-center mt-1">
              <Clock size={12} className="mr-1" />
              <span>Modified: {formatRelativeTime(quote.updatedDate)}</span>
            </div>
          </div>
          <button 
            className="p-1 bg-blue-100 hover:bg-blue-200 rounded-full transition-colors duration-200"
            onClick={() => setActiveFileMenu(prevId => prevId === getUniqueKey(quote, index) ? null : getUniqueKey(quote, index))}
          >
            <MoreHorizontal size={16} className="text-blue-800" />
          </button>
        </div>
        <div className="flex flex-wrap gap-1 mt-2">
          {quote.hideUnitPriceInPDF && (
            <span className="bg-gray-200 text-xs px-2 py-0.5 rounded-full">
              Hide Unit Price
            </span>
          )}
          {quote.hideDiscountInPDF && (
            <span className="bg-gray-200 text-xs px-2 py-0.5 rounded-full">
              Hide Discount
            </span>
          )}
          {quote.hideGSTInPDF && (
            <span className="bg-gray-200 text-xs px-2 py-0.5 rounded-full">
              Hide GST
            </span>
          )}
        </div>
        {quote.tags && quote.tags.length > 0 && (
          <div className="flex flex-wrap gap-1 mt-2">
            {quote.tags.map((tag, tagIndex) => (
              <span key={tagIndex} className="bg-gray-200 text-xs px-2 py-0.5 rounded-full">
                <Tag size={10} className="inline mr-1" />
                {tag}
              </span>
            ))}
          </div>
        )}
        {activeFileMenu === getUniqueKey(quote, index) && (
          <div ref={menuRef} className="absolute right-0 top-10 bg-gray-50 rounded-lg shadow-lg py-2 z-10 mt-2 border border-gray-300">
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
              onClick={async () => {
                if (quote.id) {
                  handleEditQuotation(quote);
                } else {
                  const duplicatedQuote = await handleDuplicateQuote(quote);
                  if (duplicatedQuote) {
                    handleEditQuotation(duplicatedQuote);
                  }
                }
                setActiveFileMenu(null);
              }}
            >
              <Edit2 size={16} className="mr-2" /> Edit
            </button>
            <hr className="border-t border-gray-200 my-1" />
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
              onClick={() => handleDuplicateQuote(quote)}
            >
              <Copy size={16} className="mr-2" /> Duplicate
            </button>
            <hr className="border-t border-gray-200 my-1" />
            <DownloadPrintablePDF
              quotationData={{
                ...quote.quotationData,
                theme: theme?.name || 'default',
                hideUnitPrice: quote.quotationData.hideUnitPriceInPDF,
              }}
              isMobile={isMobile}
              triggerCheck={refreshTrigger}
            >
              {({ loading, error, handleDownload }) => (
                <button 
                  className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload();
                    setTimeout(() => setActiveFileMenu(null), 300);
                  }}
                  disabled={loading}
                >
                  <div className="flex items-center">
                    <Download size={16} className="mr-2" />
                    <span className="text-sm">PDF</span>
                    <div className="w-4 h-4 flex items-center justify-center border-2 border-current rounded-sm ml-1.5">
                      <span className="text-[8px] font-medium">A4</span>
                    </div>
                  </div>
                </button>
              )}
            </DownloadPrintablePDF>
            <hr className="border-t border-gray-200 my-1" />
            <DownloadPDF
              quotationData={{
                ...quote.quotationData,
                theme: theme?.name || 'default',
                hideUnitPrice: quote.quotationData.hideUnitPriceInPDF,
              }}
              isMobile={isMobile}
              triggerCheck={refreshTrigger}
            >
              {({ loading, error, handleDownload }) => (
                <button 
                  className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload();
                    setTimeout(() => setActiveFileMenu(null), 300);
                  }}
                  disabled={loading}
                >
                  <div className="flex items-center">
                    <Download size={16} className="mr-2" />
                    <span className="text-sm">PDF</span>
                    <svg className="w-4 h-4 ml-1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="7" y="2" width="10" height="20" rx="2" stroke="currentColor" strokeWidth="2"/>
                      <rect x="9" y="4" width="6" height="12" rx="1" fill="currentColor" fillOpacity="0.3"/>
                      <circle cx="12" cy="19" r="1" fill="currentColor"/>
                    </svg>
                  </div>
                </button>
              )}
            </DownloadPDF>
            <hr className="border-t border-gray-200 my-1" />
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
              onClick={() => handleShareEditableFile(quote)}
            >
              <Share2 size={16} className="mr-2" /> Share Editable File
            </button>
            <hr className="border-t border-gray-200 my-1" />
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
              onClick={() => handleViewPaymentTracker(quote)}
            >
              <CreditCard size={16} className="mr-2" /> View Payment Status
            </button>
            <hr className="border-t border-gray-200 my-1" />
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center"
              onClick={() => handleViewInvoice(quote)}
            >
              <FileText size={16} className="mr-2" /> Generate Invoice
            </button>
            <hr className="border-t border-gray-200 my-1" />
            <button 
              className="w-full text-left px-4 py-2 hover:bg-blue-100 transition-colors duration-200 flex items-center text-red-600"
              onClick={() => handleDeleteQuote(quote)}
            >
              <Trash2 size={16} className="mr-2" /> Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  const formatPrice = (amount) => {
    return `₹${amount.toLocaleString('en-IN')}`;
  };

  return (
    <div ref={filesContainerRef} className="w-full max-w-md mx-auto bg-indigo-50 min-h-screen p-4 overflow-auto files-container">
      {isLoading && (
        <div className="mb-2 text-sm text-gray-600 flex items-center justify-center">
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Loading...
        </div>
      )}
      {isLoadingFile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-lg font-semibold">Loading quotation...</span>
          </div>
        </div>
      )}
      
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search files..."
          className="w-full p-2 pl-8 rounded-md border border-gray-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>

      {renderSortOptions()}

      <div className="space-y-3">
        {filteredQuotes.map((quote, index) => renderQuoteCard(quote, index))}
      </div>

      {showInvoiceGenerator && selectedQuote && (
        <InvoiceGenerator
          quotationData={{
            ...selectedQuote,
            id: selectedQuote.id,
            ...selectedQuote.quotationData,
            paymentTerms: selectedQuote.quotationData.paymentTerms || [],
            companyName: selectedQuote.quotationData.companyName,
            companyAddress: selectedQuote.quotationData.companyAddress,
            mobileNumber: selectedQuote.quotationData.mobileNumber,
            logo: selectedQuote.quotationData.logo,
            grandTotal: selectedQuote.quotationData.grandTotal
          }}
          onClose={(updatedData) => {
            setShowInvoiceGenerator(false);
            setQuotations(prevQuotations =>
              prevQuotations.map(q =>
                q.id === updatedData.id ? updatedData : q
              )
            );
          }}
        />
      )}

      {showPaymentTracker && selectedQuote && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60] p-4">
          <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            {/* Header */}
            <div className="bg-gradient-to-r from-indigo-600 to-indigo-700 p-4 rounded-t-xl flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <div className="bg-white/10 p-2 rounded-lg">
                  <CreditCard className="h-6 w-6 text-white" />
                </div>
                <div>
                  <h2 className="text-white text-xl font-bold">Payment Tracker</h2>
                  <p className="text-indigo-200 text-sm">
                    {selectedQuote.clientName} - {formatPrice(selectedQuote.totalBudget)}
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  setShowPaymentTracker(false);
                  setSelectedQuote(null);
                }}
                className="text-white/80 hover:text-white transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {/* Content */}
            <div className="p-6">
              <PaymentTracker 
                quotationData={{
                  ...selectedQuote,
                  grandTotal: selectedQuote.grandTotal,
                  payments: selectedQuote.payments || [],
                  totalPaid: selectedQuote.totalPaid || 0,
                  remainingAmount: selectedQuote.remainingAmount || selectedQuote.grandTotal
                }}
                paymentTerms={selectedQuote.paymentTerms || []}
              />
            </div>

            {/* Footer */}
            <div className="border-t p-4 flex justify-end">
              <button
                onClick={() => {
                  setShowPaymentTracker(false);
                  setSelectedQuote(null);
                }}
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Files;
