import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { auth, db } from '../Services/firebase';
import { signInWithPopup, GoogleAuthProvider, signOut, sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import { ChevronLeft, User, Bell, Palette, Lock, Globe, Database, LogOut, HelpCircle, FileText, Camera, Mail, Key, Moon, Sun, Volume2, Smartphone, Wifi, Home, UserCheck, Construction, Briefcase, Save, Trash2, Upload, CreditCard, Plus, Download, MapPin, Phone, Minus, ChevronUp, ChevronDown, List, Package, Box, Calendar, Check, Video, RefreshCw, GripVertical, Settings, ChevronRight } from 'lucide-react';
import CustomRoomNames from './CustomRoomNames';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import LegalAndAbout from './LegalAndAbout';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import PhonePePayment from './PhonePePayment';
import { themeColors } from '../utils/themeColors';
import { ThemeContext } from '../contexts/ThemeContext';
import { defaultRoomNames } from './CustomRoomNames';
import { defaultTemplateDetails } from '../utils/defaultTemplateDetails';
import { defaultItems } from '../defaultItems'; // Make sure this import is correct
import { loadDefaultItems } from '../utils/defaultItemLoader';
import { AlertTriangle } from 'lucide-react';
import { useSwipeable } from 'react-swipeable';
import imageCompression from 'browser-image-compression';
import { openDB } from 'idb';
import { format } from 'date-fns'; // Make sure to install this package
import { motion } from 'framer-motion';
import { getAppVersion } from '../utils/version'; 
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { clearCache } from '../utils/cacheHelpers';

// Add this helper function near the top of the file, before the SettingsScreen component
const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error('Error saving to localStorage:', error);
  }
};

// Define AutoResizeTextarea component within the same file
const AutoResizeTextarea = ({ value, onChange, placeholder, className }) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '20px'; // Set an initial height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ resize: 'none', overflow: 'hidden' }}
      className={`${className} py-1`} // Reduce vertical padding
    />
  );
};

const ThemeOption = ({ theme, isSelected, onSelect }) => {
  const themeColor = themeColors[theme];
  
  return (
    <button
      onClick={() => onSelect(theme)}
      className={`w-full p-4 rounded-lg flex items-center justify-between transition-all duration-300 ${
        isSelected ? `${themeColor.roomHeader} border-2 border-${theme}-500` : 'bg-white hover:bg-gray-50'
      }`}
    >
      <div className="flex items-center">
        <div className={`w-8 h-8 rounded-full ${themeColor.numberCircle} mr-3`}></div>
        <span className="font-medium">{themeColor.name}</span>
      </div>
      {isSelected && <Check className={`text-${theme}-500`} size={20} />}
    </button>
  );
};

const Cube = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey(prevKey => prevKey + 1);
    }, 4000); // Reset animation every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      key={key}
      className="w-6 h-6"
      style={{
        transformStyle: 'preserve-3d',
        perspective: '1000px',
      }}
      animate={{
        y: [0, -10, 0], // Bouncing effect
        rotateY: [0, 360], // Rotation after bouncing
        rotateX: [0, 360], // Rotation after bouncing
      }}
      transition={{
        duration: 4,
        times: [0, 0.2, 0.4, 1],
        ease: ["easeOut", "easeIn", "easeInOut"],
      }}
    >
      {/* Front face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'translateZ(12px)' }} />
      {/* Back face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(180deg) translateZ(12px)' }} />
      {/* Right face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(90deg) translateZ(12px)' }} />
      {/* Left face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateY(-90deg) translateZ(12px)' }} />
      {/* Top face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateX(90deg) translateZ(12px)' }} />
      {/* Bottom face */}
      <motion.div className="absolute inset-0 border-2 border-current" style={{ transform: 'rotateX(-90deg) translateZ(12px)' }} />
    </motion.div>
  );
};

const SettingsScreen = ({ waitingWorker, onUpdate }) => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);
  const [activeSection, setActiveSection] = useState(null);
  const [theme, setTheme] = useState('light');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const { updateUserSettings } = useFirebaseService();
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [notes, setNotes] = useState([]);
  const textAreaRefs = useRef({});
  const [expandedSection, setExpandedSection] = useState(null);
  const [customSections, setCustomSections] = useState([]);

  const { 
    getUserSettings, 
    updateUserSettings: firebaseUpdateUserSettings, 
    addProduct,
    getProducts,
    loadDefaultTemplateDetails,
    deleteUserAccount
  } = useFirebaseService();
  const { user: authUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading your settings');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [currentVersion, setCurrentVersion] = useState('');
  const [hideRoomTotalInPDF, setHideRoomTotalInPDF] = useState(() => {
    const savedValue = localStorage.getItem('hideRoomTotalInPDF');
    return savedValue !== null ? JSON.parse(savedValue) : false; // Changed default to false
  });
  const [paymentTermsTitle, setPaymentTermsTitle] = useState('Payment Terms');
  const [materialSpecsTitle, setMaterialSpecsTitle] = useState(() => {
    return localStorage.getItem('materialSpecsTitle') || 'Material Specification';
  });
  const [notesTitle, setNotesTitle] = useState('Notes');

  // Add state to track section order
  const [sectionOrder, setSectionOrder] = useState(() => {
    const savedOrder = localStorage.getItem('sectionOrder');
    return savedOrder ? JSON.parse(savedOrder) : ['paymentTerms', 'materials', 'notes'];
  });

  // Add this near the top of the component where other state variables are defined
  const [deletedSections, setDeletedSections] = useState(() => {
    const saved = localStorage.getItem('deletedSections');
    return saved ? JSON.parse(saved) : [];
  });

  // Add these state variables
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [showRenewModal, setShowRenewModal] = useState(false);

  const checkForUpdates = async () => {
    try {
      // Add timestamp and cache control headers
      const response = await fetch('/manifest.json?' + new Date().getTime(), {
        cache: 'no-store',
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      
      const manifest = await response.json();
      const currentVer = localStorage.getItem('currentVersion') || 
                        process.env.REACT_APP_VERSION || 
                        '1.613';
      
      // Add debug logging
      console.log('Version Check:', {
        current: currentVer,
        manifest: manifest.version,
        isPreview: window.location.hostname.includes('--')
      });
      
      // Compare versions properly
      const hasUpdate = compareVersions(manifest.version, currentVer) > 0;
      
      if (hasUpdate) {
        setUpdateAvailable(true);
        setLatestVersion(manifest.version);
        
        // Force service worker update check
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.ready;
          await registration.update();
        }
      }
    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  };

  // Add this useEffect to periodically check for updates
  useEffect(() => {
    checkForUpdates();
    const interval = setInterval(checkForUpdates, 30000); // Check every 30 seconds
    
    return () => clearInterval(interval);
  }, []);

  // Move these utility functions inside the component
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const calculateNextBillingDate = (nextBillingDateTimestamp) => {
    if (nextBillingDateTimestamp) {
      const nextBillingDate = nextBillingDateTimestamp.toDate();
      return formatDate(nextBillingDate);
    }
    return 'Not set';
  };

  const loadUserSettings = useCallback(async () => {
    if (user) {
      try {
        const settings = await getUserSettings(user.uid);
        if (settings) {
          setCompanyName(settings.companyName || '');
          setCompanyAddress(settings.companyAddress || '');
          setMobileNumber(settings.mobileNumber || '');
          setLogoPreview(settings.logo || null);
          setPaymentTerms(settings.paymentTerms || []);
          setMaterials(settings.materialSpecs || []);
          setNotes(settings.notes || []);
        }
      } catch (error) {
        console.error('Error loading user settings:', error);
      }
    }
  }, [user, getUserSettings]);

  useEffect(() => {
    loadUserSettings();
  }, [loadUserSettings]);

  useEffect(() => {
    if (shouldRefresh) {
      loadUserSettings();
      setShouldRefresh(false);
    }
  }, [shouldRefresh, loadUserSettings]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        console.warn('Loading timed out. Please try refreshing the page.');
        // Show an error message to the user
      }
    }, 60000); // 60 seconds timeout

    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(prev => !prev);
    }, 2000); // Toggle animation every 2 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchNextBillingDate = async () => {
      if (user) {
        console.log("Fetching billing date for user:", user.email);
        const allowedUserRef = doc(db, "allowedUsers", user.email);
        const allowedUserSnap = await getDoc(allowedUserRef);
        if (allowedUserSnap.exists()) {
          const userData = allowedUserSnap.data();
          console.log("User data:", userData);
          if (userData.nextBillingDate) {
            console.log("Next billing date:", userData.nextBillingDate.toDate());
            setNextBillingDate(userData.nextBillingDate.toDate());
          } else {
            console.log("Next billing date not found in user data");
          }
        }
      }
    };

    fetchNextBillingDate();
  }, [user]);

  const handleTextAreaResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight + 2}px`; // Add a small buffer
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setAuthError(null); // Clear any previous errors

        // Fetch company details from the database
        const userDetails = await fetchUserDetails(user.uid);
        if (userDetails) {
          setCompanyName(userDetails.companyName || '');
          setCompanyAddress(userDetails.companyAddress || '');
          setMobileNumber(userDetails.mobileNumber || '');
          setLogoPreview(userDetails.logo || null);
          setPaymentTerms(userDetails.paymentTerms || defaultTemplateDetails.paymentTerms);
          setMaterials(userDetails.materialSpecs || defaultTemplateDetails.materialSpecs);
          setNotes(userDetails.notes || defaultTemplateDetails.notes);
          setCustomSections(userDetails.customSections || []);
        }
      } else {
        setUser(null);
        setCustomSections([]); // Reset custom sections when user logs out
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserDetails = async (userId) => {
    try {
      const userDetails = await getUserSettings(userId);
      if (userDetails) {
        setCompanyName(userDetails.companyName || '');
        setCompanyAddress(userDetails.companyAddress || '');
        setMobileNumber(userDetails.mobileNumber || '');
        setLogoPreview(userDetails.logo || null);
        if (userDetails.logo) {
          await saveLogo(userDetails.logo);
        }
        setPaymentTerms(userDetails.paymentTerms || defaultTemplateDetails.paymentTerms);
        setMaterials(userDetails.materialSpecs || defaultTemplateDetails.materialSpecs);
        setNotes(userDetails.notes || defaultTemplateDetails.notes);
        setCustomSections(userDetails.customSections || []);
      }
      return userDetails;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in:', error);
      setAuthError(error.message); // Set error message for display
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      setAuthError(error.message); // Set error message for display
    }
  };

  const settingsSections = [
    { id: 'billing', icon: CreditCard, label: 'Billing & Payments', color: 'bg-blue-500' },
    { id: 'profile', icon: User, label: 'Profile', color: 'bg-green-500' },
    { id: '3dDesign', icon: Box, label: '3D Design', color: 'bg-orange-500' },
    { id: 'roomNames', icon: Home, label: 'Room Names', color: 'bg-yellow-500' },
    { id: 'defaultTemplate', icon: Settings, label: 'Default Template', color: 'bg-indigo-500' },
    { id: 'theme', icon: Palette, label: 'Theme & Display', color: 'bg-pink-500' },
    { id: 'privacy', icon: Lock, label: 'Privacy & Security', color: 'bg-red-500' },
    { id: 'support', icon: HelpCircle, label: 'Support & Contact', color: 'bg-purple-600' },
    { id: 'legal', icon: Construction, label: 'Legal & About', color: 'bg-gray-500' },
    { id: 'appDemo', icon: Video, label: 'App Demo & Guide', color: 'bg-emerald-500' },
  ];

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => setIsTransitioning(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const handleSectionChange = (sectionId) => {
    setIsTransitioning(true);
    setActiveSection(sectionId);
  };

  const toggleSection = (sectionId) => {
    setExpandedSection(expandedSection === sectionId ? null : sectionId);
  };

  const handleSectionNameChange = (setter, newName) => {
    setter(prevState => ({ ...prevState, name: newName }));
  };

  const handleCustomSectionNameChange = (index, newName) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[index].name = newName;
      return newSections;
    });
  };

  const handleCustomSectionItemChange = (sectionIndex, itemIndex, value) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items[itemIndex] = value;
      return newSections;
    });
  };

  const handleAddCustomSectionItem = (sectionIndex) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items.push('');
      return newSections;
    });
  };

  const handleRemoveCustomSectionItem = (sectionIndex, itemIndex) => {
    setCustomSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].items = newSections[sectionIndex].items.filter((_, i) => i !== itemIndex);
      return newSections;
    });
  };

  const handleAddCustomSection = () => {
    setCustomSections(prev => [...prev, { name: 'New Section', items: [''] }]);
  };

  const handleDeleteCustomSection = (index) => {
    setCustomSections(prev => prev.filter((_, i) => i !== index));
  };

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    localStorage.setItem('selectedTheme', theme);
  };

  const renderThemeOptions = () => (
    <div className="space-y-2">
      {Object.keys(themeColors).map((theme) => (
        <ThemeOption
          key={theme}
          theme={theme}
          isSelected={currentTheme === theme}
          onSelect={handleThemeChange}
        />
      ))}
    </div>
  );

  const handleDeleteSection = (sectionId) => {
    setDeletedSections(prev => {
      const newDeletedSections = [...prev, sectionId];
      localStorage.setItem('deletedSections', JSON.stringify(newDeletedSections));
      return newDeletedSections;
    });
    
    setSectionOrder(prev => {
      const newOrder = prev.filter(id => id !== sectionId);
      localStorage.setItem('sectionOrder', JSON.stringify(newOrder));
      return newOrder;
    });
  };

  const handleRestoreSection = (sectionId) => {
    setDeletedSections(prev => {
      const newDeletedSections = prev.filter(id => id !== sectionId);
      localStorage.setItem('deletedSections', JSON.stringify(newDeletedSections));
      return newDeletedSections;
    });
    
    setSectionOrder(prev => {
      const newOrder = [...prev, sectionId];
      localStorage.setItem('sectionOrder', JSON.stringify(newOrder));
      return newOrder;
    });
  };

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'billing':
        return (
          <div className="space-y-4 animate-fade-in p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Current Plan</h2>
            
            {/* Current Plan - Enhanced UI Design */}
            <div className="bg-gradient-to-br from-blue-100 to-gray-100 p-6 rounded-xl shadow-lg border border-blue-200">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <CreditCard className="w-8 h-8 mr-3 text-blue-500" />
                  <div>
                    <p className="text-2xl font-bold text-gray-800">Pro Plan</p>
                    <div className="flex items-center gap-3 mb-1">
                      <span className="text-xl text-gray-600 relative">
                        ₹500
                        <span className="absolute inset-0 transform rotate-[-8deg] border-t-2 border-red-500/90" style={{ top: '50%' }}></span>
                      </span>
                      <span className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                        -60% OFF
                      </span>
                    </div>
                    <div className="flex items-baseline gap-1">
                      <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                        ₹199
                      </span>
                      <span className="text-sm text-gray-600 font-medium">/ month</span>
                    </div>
                    <p className="text-sm text-green-600 font-medium mt-1">Save ₹301 on subscription!</p>
                  </div>
                </div>
                <div className="bg-green-500 text-white px-3 py-1 rounded-full flex items-center">
                  <Check className="w-4 h-4 mr-1" />
                  <span className="font-semibold text-sm">Active</span>
                </div>
              </div>
              <div className="flex items-center text-sm font-medium">
                <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                <span className="text-base font-semibold text-gray-700">
                  Next billing date: {
                    nextBillingDate 
                      ? format(nextBillingDate, 'dd MMM yyyy, hh:mm a')
                      : 'Not set'
                  }
                </span>
              </div>
            </div>

            {/* Subscription Plans */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Subscription Plans</h3>
              <div className="grid gap-4">
                {/* Monthly Plan */}
                <div className="p-4 border border-blue-200 rounded-lg bg-blue-50">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="text-lg font-semibold text-blue-800">🚀 Monthly Plan</span>
                      <p className="text-2xl font-bold text-blue-600 mt-1">₹199</p>
                    </div>
                    <a 
                      href="https://wa.me/919966922000?text=Hi,%20I%20would%20like%20to%20subscribe%20to%20the%20Monthly%20Plan%20(₹199)" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                    >
                      <FaWhatsapp className="w-4 h-4 mr-2" />
                      Select
                    </a>
                  </div>
                </div>

                {/* 6-Month Plan */}
                <div className="p-4 border border-purple-200 rounded-lg bg-purple-50">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="text-lg font-semibold text-purple-800">🗓 6-Month Plan</span>
                      <p className="text-2xl font-bold text-purple-600 mt-1">₹699</p>
                    </div>
                    <a 
                      href="https://wa.me/919966922000?text=Hi,%20I%20would%20like%20to%20subscribe%20to%20the%206-Month%20Plan%20(₹699)" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center"
                    >
                      <FaWhatsapp className="w-4 h-4 mr-2" />
                      Select
                    </a>
                  </div>
                </div>

                {/* 1-Year Plan */}
                <div className="p-4 border border-emerald-200 rounded-lg bg-emerald-50">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="text-lg font-semibold text-emerald-800">💎 1-Year Plan</span>
                      <p className="text-2xl font-bold text-emerald-600 mt-1">₹999</p>
                    </div>
                    <a 
                      href="https://wa.me/919966922000?text=Hi,%20I%20would%20like%20to%20subscribe%20to%20the%201-Year%20Plan%20(₹999)" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center"
                    >
                      <FaWhatsapp className="w-4 h-4 mr-2" />
                      Select
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Subscription Management */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">Subscription Management</h3>
              <p className="text-gray-600 mb-2">Please contact Design Que to manage your subscription.</p>
              <div className="space-y-2">
                <a 
                  href="https://wa.me/919966922000" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center text-green-600 hover:text-green-800"
                >
                  <FaWhatsapp className="w-5 h-5 mr-2" />
                  <span>+919966922000</span>
                </a>
              </div>
            </div>

            {/* Refund Policy */}
            <div className="mt-6">
              <button
                onClick={() => setShowRefundPolicy(!showRefundPolicy)}
                className="text-sm text-blue-600 hover:text-blue-800 focus:outline-none flex items-center"
              >
                <FileText className="w-4 h-4 mr-2" />
                View Refund Policy
              </button>
              {showRefundPolicy && (
                <div className="mt-3 p-4 bg-gray-50 rounded-lg text-sm text-gray-700 border border-gray-200">
                  <p className="mb-2">
                    At Design Que, we are committed to providing high-quality services. Please note our no-refund policy:
                  </p>
                  <ul className="list-disc list-inside mb-2">
                    <li>All purchases are final and non-refundable.</li>
                    <li>Subscription fees are charged in advance and are non-refundable.</li>
                    <li>We do not prorate or offer partial refunds for unused time on active subscriptions.</li>
                    <li>Cancellations will take effect at the end of the current billing cycle.</li>
                  </ul>
                  <p className="mb-2">
                    We encourage users to carefully review our services before making a purchase. If you have any questions about our offerings, please contact us before subscribing.
                  </p>
                  <p className="mb-2">
                    While we don't offer refunds, we are committed to resolving any technical issues you may encounter while using our service. Our support team is available to assist you with any problems or concerns.
                  </p>
                  <p>
                    For any questions about this policy or our services, please contact our support team at <a href="mailto:support@designque.in" className="text-blue-600 hover:underline">support@designque.in</a>.
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      case 'profile':
        return (
          <div className="animate-fade-in p-4 bg-white rounded-lg shadow-md">
            {/* Profile Header */}
            <div className="text-center pb-6 border-b border-gray-200">
              <div className="w-20 h-20 mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full flex items-center justify-center text-white text-2xl font-bold mb-4">
                {user?.email?.charAt(0).toUpperCase() || 'U'}
              </div>
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Profile Settings</h2>
              {user && (
                <div className="flex items-center justify-center text-gray-600">
                  <Mail className="w-4 h-4 mr-2" />
                  <span>{user.email}</span>
                </div>
              )}
            </div>

            {/* Account Status */}
            <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <div className="w-8 h-8 bg-green-500 rounded-full flex items-center justify-center">
                    <Check className="w-5 h-5 text-white" />
                  </div>
                  <span className="ml-3 font-medium text-gray-800">Account Status</span>
                </div>
                <span className="px-3 py-1 bg-green-500 text-white text-sm font-medium rounded-full">
                  Active
                </span>
              </div>
              <p className="text-sm text-gray-600 ml-11">
                Your account is active and in good standing
              </p>
              <div className="mt-3 flex items-center ml-11">
                <Calendar className="w-4 h-4 mr-2 text-blue-500" />
                <span className="text-sm font-semibold text-gray-700">
                  Next billing date: {
                    nextBillingDate 
                      ? format(nextBillingDate, 'dd MMM yyyy, hh:mm a')
                      : 'Not set'
                  }
                </span>
              </div>
            </div>

            {/* Subscription Plans Link */}
            <div className="mt-6 p-4 bg-gradient-to-r from-purple-50 to-pink-50 rounded-lg cursor-pointer hover:from-purple-100 hover:to-pink-100 transition-colors duration-300"
                 onClick={() => handleSectionChange('billing')}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center">
                    <CreditCard className="w-5 h-5 text-white" />
                  </div>
                  <div className="ml-3">
                    <span className="font-medium text-gray-800">Subscription Plans</span>
                    <p className="text-sm text-gray-600">View available plans and pricing</p>
                  </div>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </div>
            </div>

            {/* Account Management */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Account Management</h3>
              
              {/* Sign Out Button */}
              <button 
                onClick={handleSignOut}
                className="w-full mb-3 p-3 bg-white border-2 border-gray-200 rounded-lg hover:bg-gray-50 transition duration-200 flex items-center justify-center text-gray-700 font-medium"
              >
                <LogOut className="w-5 h-5 mr-2" />
                Sign Out
              </button>

              {/* Delete Account Button */}
              <button 
                onClick={() => setShowDeleteConfirmation(true)}
                className="w-full p-3 bg-white border-2 border-red-200 text-red-600 rounded-lg hover:bg-red-50 transition duration-200 flex items-center justify-center font-medium"
              >
                <Trash2 className="w-5 h-5 mr-2" />
                Delete Account
              </button>
            </div>

            {/* Delete Account Confirmation Modal */}
            {showDeleteConfirmation && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
                  <div className="flex items-center mb-4">
                    <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mr-4">
                      <AlertTriangle className="w-6 h-6 text-red-600" />
                    </div>
                    <h2 className="text-xl font-bold text-gray-800">Delete Account?</h2>
                  </div>
                  <p className="mb-6 text-gray-600">
                    This action cannot be undone. All your data will be permanently removed.
                  </p>
                  <div className="flex justify-end space-x-3">
                    <button 
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition duration-200 font-medium"
                    >
                      Cancel
                    </button>
                    <button 
                      onClick={handleDeleteAccount}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-200 font-medium flex items-center"
                    >
                      <Trash2 className="w-4 h-4 mr-2" />
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case '3dDesign':
        return (
          <div className="animate-fade-in">
            <h2 className="text-2xl font-bold mb-4">3D Design Service</h2>
            <p className="text-lg mb-4">3D design services start from ₹ 18,000</p>
            <div className="flex justify-center mb-4">
              <div className="w-64 h-[512px]"> {/* Adjust width and height as needed */}
                <iframe
                  src="https://www.youtube.com/embed/S8bMTTaw9PY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-xl font-semibold mb-2">Contact Us Now</h3>
              <div className="flex flex-col space-y-2">
                <a 
                  href="mailto:info@designque.in" 
                  className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                >
                  <FaEnvelope className="h-5 w-5" />
                  <span>info@designque.in</span>
                </a>
                <a 
                  href="https://wa.me/919966922000" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center space-x-2 text-green-600 hover:text-green-800"
                >
                  <FaWhatsapp className="h-5 w-5" />
                  <span>+919966922000</span>
                </a>
              </div>
            </div>
          </div>
        );
      case 'theme':
        return (
          <div className="space-y-4 animate-fade-in">
            <h2 className="text-xl font-semibold mb-4">Theme & Display</h2>
            {renderThemeOptions()}
          </div>
        );
      case 'privacy':
        return (
          <div className="space-y-4 animate-fade-in p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800">Privacy & Security</h2>
            <p className="text-gray-600">
              At Design Que, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Collection</h3>
            <p className="text-gray-600">
              We collect personal information that you provide to us when you register for an account, use our services, or communicate with us. This may include your name, email address, phone number, and payment information.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Usage</h3>
            <p className="text-gray-600">
              Your data is used to provide and improve our services, process transactions, and communicate with you about your account and our services. We may also use your information to send you promotional materials, but you can opt-out at any time.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Data Security</h3>
            <p className="text-gray-600">
              We implement a variety of security measures to maintain the safety of your personal information. Your data is stored on secure servers, and we use encryption to protect sensitive information during transmission.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Your Rights</h3>
            <p className="text-gray-600">
              You have the right to access, correct, or delete your personal information at any time. If you have any questions or concerns about your data, please contact our support team.
            </p>
            <h3 className="text-lg font-semibold text-gray-700">Changes to This Policy</h3>
            <p className="text-gray-600">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. Please review this policy periodically for any updates.
            </p>
            <p className="text-gray-600">
              For more information, please contact us at <a href="mailto:support@designque.in" className="text-blue-600 hover:underline">support@designque.in</a>.
            </p>
          </div>
        );
      case 'roomNames':
        return (
          <div className="space-y-4 animate-fade-in">
            <CustomRoomNames />
          </div>
        );
      case 'support':
        return (
          <div className="space-y-4 animate-fade-in">
            <h2 className="text-lg font-semibold">Support & Contact</h2>
            <div className="flex flex-col space-y-4">
              <a 
                href="mailto:info@designque.in" 
                className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
              >
                <FaEnvelope className="h-5 w-5" />
                <span>Email us at info@designque.in</span>
              </a>
              <a 
                href="https://wa.me/919966922000" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center space-x-2 text-green-600 hover:text-green-800"
              >
                <FaWhatsapp className="h-5 w-5" />
                <span>WhatsApp: +919966922000</span>
              </a>
            </div>
          </div>
        );
      case 'legal':
        return <LegalAndAbout />;
      case 'defaultTemplate':
        return (
          <div className="w-full max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Default Template</h2>
            <div className="space-y-6">
              <div className="flex">
                <div className="w-2/3 pr-2">
                  <input 
                    className="w-full p-2 mb-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs font-bold" 
                    placeholder="Company Name" 
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <div className="relative">
                    <MapPin size={16} className="absolute top-2 left-3 text-gray-500" />
                    <textarea 
                      className="w-full p-2 mb-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-xs pl-8" 
                      placeholder="Company Details (Address, GST, etc.)" 
                      rows="3"
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      style={{ resize: 'none', height: '80px', fontSize: '0.75rem' }}
                    />
                  </div>
                  <div className="relative">
                    <Phone size={16} className="absolute top-2 left-3 text-gray-500" />
                    <input 
                      className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm pl-8" 
                      placeholder="Mobile Number" 
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/3 pl-2">
                  {logoPreview && (
                    <div className="relative w-full aspect-square mb-3 flex items-center justify-center rounded-lg">
                      <img 
                        src={logoPreview} 
                        alt="Company Logo" 
                        className="w-full h-auto object-contain rounded-lg" 
                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <div 
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="space-y-4"
                    >
                      {sectionOrder.map((sectionId, index) => (
                        <Draggable key={sectionId} draggableId={sectionId} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="flex items-center justify-between mb-2 cursor-pointer bg-white rounded-lg shadow-sm">
                                {/* Drag Handle */}
                                <div
                                  {...provided.dragHandleProps}
                                  className="px-2 py-3 hover:bg-gray-100 rounded-l-lg"
                                >
                                  <GripVertical size={20} className="text-gray-400" />
                                </div>
                                
                                {/* Section Icon and Title - Make this clickable for expansion */}
                                <div 
                                  className="flex items-center flex-grow p-2"
                                  onClick={() => toggleSection(sectionId)}
                                >
                                  {sectionId === 'paymentTerms' && <CreditCard size={20} className="mr-2 text-blue-500" />}
                                  {sectionId === 'materials' && <Package size={20} className="mr-2 text-green-500" />}
                                  {sectionId === 'notes' && <FileText size={20} className="mr-2 text-yellow-500" />}
                                  <input
                                    type="text"
                                    value={getSectionTitle(sectionId)}
                                    onChange={(e) => handleTitleChange(sectionId, e.target.value)}
                                    className="text-sm font-medium bg-transparent border-b border-transparent hover:border-gray-300 focus:border-blue-500 focus:outline-none"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                                
                                {/* Expand/Collapse indicator */}
                                <div className="flex items-center">
                                  <button
                                    onClick={() => toggleSection(sectionId)}
                                    className="p-2 text-gray-500 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                                  >
                                    {expandedSection === sectionId ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                  </button>
                                  
                                  {/* Delete button */}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteSection(sectionId);
                                    }}
                                    className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors duration-200"
                                  >
                                    <Trash2 size={16} />
                                  </button>
                                </div>
                              </div>

                              {/* Add section content below */}
                              {expandedSection === sectionId && (
                                <div className="p-4 bg-gray-50 rounded-lg mt-2">
                                  {renderSectionContent(sectionId)}
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* Add Deleted Sections List */}
              {deletedSections.length > 0 && (
                <div className="mt-6 border-t pt-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Deleted Sections</h3>
                  <div className="space-y-2">
                    {deletedSections.map((sectionId) => (
                      <div key={sectionId} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                        <span className="text-sm text-gray-600">
                          {getSectionTitle(sectionId)}
                        </span>
                        <button
                          onClick={() => handleRestoreSection(sectionId)}
                          className="flex items-center space-x-1 text-sm text-blue-500 hover:text-blue-700"
                        >
                          <Plus size={16} />
                          <span>Restore</span>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Hide Room Total toggle */}
              <div className="border-t pt-4 mt-4">
                <div className="flex items-center justify-between">
                  <div className="flex-grow">
                    <label className="text-sm font-medium text-gray-700">Hide Room Total in PDF</label>
                    <p className="text-xs text-gray-500">Hide the total value for each room in PDF exports</p>
                  </div>
                  <div className="relative ml-4">
                    <input
                      type="checkbox"
                      checked={hideRoomTotalInPDF}
                      onChange={(e) => e.stopPropagation()}
                      className="sr-only"
                    />
                    <div
                      className={`block w-14 h-8 rounded-full transition-colors duration-200 ${
                        hideRoomTotalInPDF ? 'bg-green-500' : 'bg-gray-300'
                      }`}
                      onClick={() => {
                        const newValue = !hideRoomTotalInPDF;
                        setHideRoomTotalInPDF(newValue);
                        saveToLocalStorage('hideRoomTotalInPDF', newValue);
                      }}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ${
                        hideRoomTotalInPDF ? 'transform translate-x-full' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newValue = !hideRoomTotalInPDF;
                        setHideRoomTotalInPDF(newValue);
                        saveToLocalStorage('hideRoomTotalInPDF', newValue);
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              {/* Save button */}
              <button 
                className="w-full bg-indigo-500 text-white px-4 py-3 rounded-md hover:bg-indigo-600 transition-colors duration-200 flex items-center justify-center"
                onClick={saveCompanyDetails}
              >
                <Save size={20} className="mr-2" /> Save Default Template
              </button>
            </div>
          </div>
        );
      case 'appDemo':
        return (
          <div className="space-y-5 animate-fade-in">
            <h2 className="text-lg font-semibold text-gray-800 text-center mb-4">
              Quick Start App Demo & Guide
            </h2>
            
            {/* Tutorial 1 */}
            <div className="relative w-10/12 sm:w-3/5 mx-auto group">
              <div className="rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-lg overflow-hidden" style={{ aspectRatio: '16/7' }}>
                  {/* Animated Wave Pattern Background */}
                  <div className="absolute inset-0 opacity-20">
                    <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                      <pattern id="waves" width="40" height="15" patternUnits="userSpaceOnUse">
                        <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="0.8" 
                              className="text-emerald-400"/>
                      </pattern>
                      <rect width="200" height="100" fill="url(#waves)"/>
                    </svg>
                  </div>

                  {/* Tutorial Number */}
                  <div className="absolute left-4 top-4 text-emerald-600 text-sm font-medium">
                    01
                  </div>

                  {/* Play Button Link */}
                  <a 
                    href="https://youtube.com/shorts/8wj0ISfBTTE?si=5OVoMVhds-t-U7jC"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="w-14 h-14 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-sm">
                      <svg className="w-7 h-7 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                      </svg>
                    </div>
                  </a>
                </div>
                
                {/* Title Below Video with white background and smooth corners */}
                <div className="py-2 px-3 text-center bg-white rounded-lg">
                  <p className="text-gray-800 text-sm">
                    App installation tutorial for iPhone, Android, Windows
                  </p>
                </div>
              </div>
            </div>

            {/* Tutorial 2 */}
            <div className="relative w-10/12 sm:w-3/5 mx-auto group">
              <div className="rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="relative bg-gradient-to-r from-teal-50 via-emerald-50 to-teal-50 rounded-lg overflow-hidden" style={{ aspectRatio: '16/7' }}>
                  {/* Animated Wave Pattern Background */}
                  <div className="absolute inset-0 opacity-20">
                    <svg className="w-[200%] h-full animate-wave" viewBox="0 0 200 100" preserveAspectRatio="none">
                      <pattern id="waves2" width="40" height="15" patternUnits="userSpaceOnUse">
                        <path d="M0 7.5 Q10 0, 20 7.5 Q30 15, 40 7.5" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="0.8" 
                              className="text-emerald-400"/>
                      </pattern>
                      <rect width="200" height="100" fill="url(#waves2)"/>
                    </svg>
                  </div>

                  {/* Tutorial Number */}
                  <div className="absolute left-4 top-4 text-emerald-600 text-sm font-medium">
                    02
                  </div>

                  {/* Play Button Link */}
                  <a 
                    href="https://youtu.be/yWC31ZkR5as"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex items-center justify-center"
                  >
                    <div className="w-14 h-14 rounded-full border-2 border-emerald-200 bg-white/90 flex items-center justify-center group-hover:border-emerald-300 group-hover:bg-white transition-all duration-300 shadow-sm">
                      <svg className="w-7 h-7 text-emerald-600 group-hover:text-emerald-700 transition-colors duration-300 translate-x-0.5" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M6.92 5.51L18.9 12l-11.98 6.49V5.51z"/>
                      </svg>
                    </div>
                  </a>
                </div>
                
                {/* Title Below Video with white background and smooth corners */}
                <div className="py-2 px-3 text-center bg-white rounded-lg">
                  <p className="text-gray-800 text-sm">
                    App Demo
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="animate-fade-in">
            <p className="text-gray-500">Select a setting to view options.</p>
          </div>
        );
    }
  };

  const handleLostPassword = async () => {
    const email = prompt("Please enter your email address to reset your password:");
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset email sent. Please check your inbox.");
      } catch (error) {
        console.error("Error sending password reset email:", error);
        alert("Failed to send password reset email. Please try again.");
      }
    }
  };

  const updatePhoneNumber = async (userId, newPhoneNumber) => {
    try {
      const user = auth.currentUser;
      await updateProfile(user, { phoneNumber: newPhoneNumber });
      alert("Phone number updated successfully.");
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert("Failed to update phone number. Please try again.");
    }
  };

  const handleChangePhoneNumber = async () => {
    const newPhoneNumber = prompt("Please enter your new phone number:");
    if (newPhoneNumber) {
      await updatePhoneNumber(user.uid, newPhoneNumber);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressImage(file);
        
        if (compressedFile.size > 800 * 1024) {
          throw new Error("Image is too large. Please choose a smaller image.");
        }

        const reader = new FileReader();
        reader.onloadend = async (e) => {
          const logoData = e.target.result;
          setLogoPreview(logoData);
          await saveLogo(logoData);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error processing logo:", error);
        alert(error.message || "Error processing logo. Please try again with a smaller image.");
      }
    }
  };

  const deleteLogo = () => {
    setLogoPreview(null);
  };

  const saveCompanyDetails = async () => {
    if (authUser) {
      try {
        const detailsToSave = {
          companyName: companyName || '',
          companyAddress: companyAddress || '',
          mobileNumber: mobileNumber || '',
          logo: logoPreview,
          paymentTerms: paymentTerms,
          materialSpecs: materials,
          notes: notes,
          isFirstTimeUser: false
        };

        await firebaseUpdateUserSettings(authUser.uid, detailsToSave);
        console.log('Company details saved successfully');
        alert('Company details saved successfully!');

      } catch (error) {
        console.error('Error saving company details:', error);
        alert(`Failed to save company details. Error: ${error.message}`);
      }
    } else {
      console.error('No user found when trying to save company details');
      alert('No user found. Please ensure you are logged in.');
    }
  };

  const handlePayment = (method) => {
    console.log(`Processing payment with ${method}`);
    setShowPaymentOptions(false);
  };

  const handleDeleteAccount = async () => {
    if (user) {
      try {
        const success = await deleteUserAccount(user);
        if (success) {
          alert('Your account has been successfully deleted.');
          // Redirect to login or home page
          // You might need to implement this navigation logic
        } else {
          alert('Failed to delete account. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('An error occurred while deleting your account. Please try again.');
      }
    }
  };

  const handleObjectArrayChange = (setter, index, field, value) => {
    setter(prev => {
      const newArray = [...prev];
      newArray[index][field] = value;
      return newArray;
    });
  };

  const handleArrayChange = (setter, index, value) => {
    setter(prev => {
      const newArray = [...prev];
      newArray[index] = value;
      return newArray;
    });
  };

  const handleAddObjectItem = (setter, template) => {
    setter(prev => [...prev, template]);
  };

  const handleAddItem = (setter) => {
    setter(prev => [...prev, '']);
  };

  const handleRemoveItem = (setter, index) => {
    setter(prev => prev.filter((_, i) => i !== index));
  };

  const renderIcon = (Icon) => <Icon size={28} />;

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  const saveLogo = async (logoData) => {
    const db = await initDB();
    await db.put('logos', logoData, 'currentLogo');
  };

  const initDB = async () => {
    return openDB('QuotationApp', 1, {
      upgrade(db) {
        db.createObjectStore('logos');
      },
    });
  };

  useEffect(() => {
    const handleNavigateToSection = (event) => {
      if (event.detail.section) {
        handleSectionChange(event.detail.section);
      }
    };

    document.querySelector('.settings-screen')?.addEventListener('navigateToSection', handleNavigateToSection);

    return () => {
      document.querySelector('.settings-screen')?.removeEventListener('navigateToSection', handleNavigateToSection);
    };
  }, []);

  useEffect(() => {
    const checkVersion = async () => {
      const version = await getAppVersion();
      setCurrentVersion(version);
    };
    
    checkVersion();
    const interval = setInterval(checkVersion, 60000);
    
    return () => clearInterval(interval);
  }, []);

  const handleTitleChange = (type, value) => {
    switch(type) {
      case 'paymentTerms':
        setPaymentTermsTitle(value);
        localStorage.setItem('paymentTermsTitle', value);
        window.dispatchEvent(new Event('storage'));
        break;
      case 'materials':
        setMaterialSpecsTitle(value);
        localStorage.setItem('materialSpecsTitle', value);
        window.dispatchEvent(new Event('storage'));
        break;
      case 'notes':
        setNotesTitle(value);
        localStorage.setItem('notesTitle', value);
        window.dispatchEvent(new Event('storage'));
        break;
    }
  };

  useEffect(() => {
    const loadSavedTitles = () => {
      const savedPaymentTermsTitle = localStorage.getItem('paymentTermsTitle');
      const savedMaterialSpecsTitle = localStorage.getItem('materialSpecsTitle');
      const savedNotesTitle = localStorage.getItem('notesTitle');

      if (savedPaymentTermsTitle) setPaymentTermsTitle(savedPaymentTermsTitle);
      if (savedMaterialSpecsTitle) setMaterialSpecsTitle(savedMaterialSpecsTitle);
      if (savedNotesTitle) setNotesTitle(savedNotesTitle);
    };

    loadSavedTitles();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(sectionOrder);
    const [reorderedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, reorderedItem);

    setSectionOrder(newOrder);
    localStorage.setItem('sectionOrder', JSON.stringify(newOrder));
    window.dispatchEvent(new Event('storage'));
  };

  const getSectionTitle = (sectionId) => {
    switch (sectionId) {
      case 'paymentTerms':
        return paymentTermsTitle;
      case 'materials':
        return materialSpecsTitle;
      case 'notes':
        return notesTitle;
      default:
        return '';
    }
  };

  const renderSectionContent = (sectionId) => {
    switch (sectionId) {
      case 'paymentTerms':
        return (
          <div className="space-y-2">
            {paymentTerms.map((term, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="relative w-24">
                  <input
                    type="number"
                    value={term.percentage}
                    onChange={(e) => handleObjectArrayChange(setPaymentTerms, index, 'percentage', e.target.value)}
                    className="w-full p-1 pr-6 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                    placeholder="0"
                  />
                  <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm">%</span>
                </div>
                <AutoResizeTextarea
                  value={term.description}
                  onChange={(e) => handleObjectArrayChange(setPaymentTerms, index, 'description', e.target.value)}
                  placeholder="Payment term description"
                  className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                />
                <button
                  onClick={() => handleRemoveItem(setPaymentTerms, index)}
                  className="text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            ))}
            <button
              className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white hover:bg-blue-600"
              onClick={() => handleAddObjectItem(setPaymentTerms, { percentage: '', description: '' })}
            >
              <Plus size={16} />
            </button>
          </div>
        );
      case 'materials':
        return (
          <div className="space-y-2">
            {materials.map((spec, index) => (
              <div key={index} className="flex items-center space-x-2">
                <AutoResizeTextarea
                  value={spec.name}
                  onChange={(e) => handleObjectArrayChange(setMaterials, index, 'name', e.target.value)}
                  placeholder="Material name"
                  className="w-1/3 p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                />
                <AutoResizeTextarea
                  value={spec.description}
                  onChange={(e) => handleObjectArrayChange(setMaterials, index, 'description', e.target.value)}
                  placeholder="Material description"
                  className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                />
                <button
                  onClick={() => handleRemoveItem(setMaterials, index)}
                  className="text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            ))}
            <button
              className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-green-500 text-white hover:bg-green-600"
              onClick={() => handleAddObjectItem(setMaterials, { name: '', description: '' })}
            >
              <Plus size={16} />
            </button>
          </div>
        );
      case 'notes':
        return (
          <div className="space-y-2">
            {notes.map((note, index) => (
              <div key={index} className="flex items-center">
                <AutoResizeTextarea
                  value={note}
                  onChange={(e) => handleArrayChange(setNotes, index, e.target.value)}
                  placeholder="Enter note"
                  className="flex-grow p-1 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm h-6"
                />
                <button
                  onClick={() => handleRemoveItem(setNotes, index)}
                  className="ml-2 text-red-500 hover:bg-red-100 p-1 rounded-full transition-colors duration-200"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            ))}
            <button
              className="mt-2 flex items-center justify-center w-6 h-6 rounded-full bg-yellow-500 text-white hover:bg-yellow-600"
              onClick={() => handleAddItem(setNotes)}
            >
              <Plus size={16} />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const handleUpdate = async () => {
    if (!updateAvailable) return;
    
    try {
      setIsUpdating(true);
      
      // Clear all caches
      const cacheKeys = await caches.keys();
      await Promise.all(
        cacheKeys.map(key => caches.delete(key))
      );
      
      // Update version in localStorage
      if (latestVersion) {
        localStorage.setItem('currentVersion', latestVersion);
        // Remove pending update flag if it exists
        localStorage.removeItem('pendingUpdate');
        
        // Update state immediately
        setCurrentVersion(latestVersion);
        setUpdateAvailable(false);
        setLatestVersion('');
      }
      
      if (waitingWorker && waitingWorker.postMessage) {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      }
      
      // Force service worker update
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
          await registration.update();
        }
      }
      
      // Reload the page after a short delay
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
      
    } catch (error) {
      console.error('Update failed:', error);
      alert('Update failed. Please try refreshing the page.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Add this useEffect to handle pending updates
  useEffect(() => {
    const pendingUpdate = localStorage.getItem('pendingUpdate');
    const storedVersion = localStorage.getItem('currentVersion');
    
    if (pendingUpdate && storedVersion) {
      // Clear the pending update flags
      localStorage.removeItem('pendingUpdate');
      localStorage.removeItem('currentVersion');
      
      // Update the current version
      setCurrentVersion(storedVersion);
      setUpdateAvailable(false);
      setLatestVersion('');
      
      // Force a version check after a short delay
      setTimeout(checkForUpdates, 1000);
      
      // Show success message
      alert('Application successfully updated to version ' + storedVersion);
    }
  }, []);

  const compareVersions = (v1, v2) => {
    console.log(`Comparing versions: ${v1} vs ${v2}`);
    const parts1 = v1.split('.').map(Number);
    const parts2 = v2.split('.').map(Number);
    
    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const part1 = parts1[i] || 0;
      const part2 = parts2[i] || 0;
      if (part1 > part2) return 1;
      if (part1 < part2) return -1;
    }
    return 0;
  };

  useEffect(() => {
    // Check for updates immediately
    checkForUpdates();
    
    // Set up periodic check every 5 minutes
    const interval = setInterval(checkForUpdates, 300000);
    
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Add this effect to handle service worker updates
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.addEventListener('controllerchange', () => {
          // The service worker has been updated
          window.location.reload(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const allowedUserRef = doc(db, "allowedUsers", user.email);
        const allowedUserSnap = await getDoc(allowedUserRef);
        
        if (allowedUserSnap.exists()) {
          const userData = allowedUserSnap.data();
          const now = Date.now();

          if (userData.status === 'inactive') {
            setSubscriptionStatus('inactive');
            return;
          }

          // If nextBillingDate exists, check if it's expired
          if (userData.nextBillingDate) {
            const nextBilling = userData.nextBillingDate.toDate().getTime();
            setNextBillingDate(nextBilling);
            setSubscriptionStatus(now <= nextBilling ? 'active' : 'expired');
          } else {
            // If no nextBillingDate, treat as active
            setSubscriptionStatus('active');
          }
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    checkSubscriptionStatus();
  }, []);

  // Add this before the settings content
  const renderSubscriptionStatus = () => {
    // Only show status if subscription is expired or inactive
    if (!subscriptionStatus || subscriptionStatus === 'active') return null;

    const getStatusColor = () => {
      switch (subscriptionStatus) {
        case 'expired': return 'bg-red-100 text-red-800';
        case 'inactive': return 'bg-gray-100 text-gray-800';
        default: return 'bg-gray-100 text-gray-800';
      }
    };

    const getStatusText = () => {
      switch (subscriptionStatus) {
        case 'expired': return 'Subscription Expired';
        case 'inactive': return 'Inactive Subscription';
        default: return 'Unknown Status';
      }
    };

    return (
      <div className="mb-6 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <div className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor()}`}>
              {getStatusText()}
            </div>
          </div>
          <button
            onClick={() => setShowRenewModal(true)}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors"
          >
            Renew Now
          </button>
        </div>
        {subscriptionStatus === 'expired' && (
          <p className="text-sm text-gray-600">
            Your subscription has expired. Renew now to continue using all features including company logo in PDFs.
          </p>
        )}
      </div>
    );
  };

  // Add renewal modal
  const RenewalModal = () => {
    if (!showRenewModal) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg max-w-md w-full p-6">
          <h3 className="text-lg font-semibold mb-4">Renew Subscription</h3>
          <p className="text-gray-600 mb-6">
            Contact us to renew your subscription and continue using all features:
          </p>
          <div className="flex flex-col space-y-4">
            <a
              href="https://wa.me/919966922000"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-5 h-5 mr-2">
                <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
              </svg>
              WhatsApp Us
            </a>
            <button
              onClick={() => setShowRenewModal(false)}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="settings-screen p-4 bg-gray-50 min-h-screen">
      {renderSubscriptionStatus()}
      <RenewalModal />
      <header 
        className="p-4 flex items-center justify-between transition-all duration-300 ease-in-out mb-4"
      >
        <div className="flex items-center">
          {activeSection && (
            <button 
              onClick={() => handleSectionChange(null)}
              className="mr-2 transition-opacity duration-300 ease-in-out"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
          )}
          <h1 className="text-xl font-semibold">
            {activeSection ? settingsSections.find(s => s.id === activeSection)?.label : 'Settings'}
          </h1>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
            Version {currentVersion}
          </span>
          {updateAvailable && (
            <button
              onClick={handleUpdate}
              disabled={isUpdating}
              className={`flex items-center space-x-1 text-sm font-medium text-white ${
                isUpdating ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
              } px-3 py-1 rounded-full transition-colors duration-200`}
            >
              <RefreshCw className={`h-3.5 w-3.5 mr-1 ${isUpdating ? 'animate-spin' : ''}`} />
              <span>{isUpdating ? 'Updating...' : `Update to ${latestVersion}`}</span>
            </button>
          )}
        </div>
      </header>

      <main className="p-4">
        {authError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{authError}</span>
          </div>
        )}

        {activeSection === 'billing' && user && (
          <div className="mb-4">
            <p className="text-sm mb-2">Signed in as: <strong>{user.email}</strong></p>
            <button onClick={handleSignOut} className="w-full p-2 bg-red-500 text-white rounded-md">Sign Out</button>
          </div>
        )}

        {activeSection ? (
          <div className={`transition-opacity duration-300 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
            {renderSettingsContent()}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {settingsSections.map((section) => (
              <motion.button
                key={section.id}
                onClick={() => handleSectionChange(section.id)}
                className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-sm ${section.color} text-white h-28`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {section.id === '3dDesign' ? (
                  <Cube />
                ) : (
                  renderIcon(section.icon)
                )}
                <span className="mt-2 text-sm font-medium text-center">
                  {section.label}
                </span>
              </motion.button>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};


export default SettingsScreen;