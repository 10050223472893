import React, { useState, useEffect } from 'react';
import { Download, Calendar, FileText, DollarSign, ChevronDown, CreditCard, X } from 'lucide-react';
import { format } from 'date-fns';
import { pdf } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';
import InvoicePDFTemplate from './InvoicePDF';
import PaymentTracker from './payment/PaymentTracker';
import { useFirebaseService } from '../hooks/useFirebaseService';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Services/firebase';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Register the fonts before using them
Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf', fontWeight: 'normal' },
    { src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' }
  ]
});

const InvoiceGenerator = ({ quotationData, onClose }) => {
  const { updateQuotation } = useFirebaseService();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [isEditingAmount, setIsEditingAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentTracker, setShowPaymentTracker] = useState(false);
  const [showSavePaymentModal, setShowSavePaymentModal] = useState(false);
  const [customPaymentTerm, setCustomPaymentTerm] = useState('');
  const [isCustomTerm, setIsCustomTerm] = useState(false);

  // Get payment terms from quotation data or use default terms
  const paymentTerms = quotationData.paymentTerms || [];

  // Add new state to track field validation
  const [validationHighlight, setValidationHighlight] = useState({
    paymentTerm: !selectedPaymentTerm,
    paymentMethod: !paymentMethod,
    paymentAmount: !paymentAmount
  });

  // Add useEffect to update validation state
  useEffect(() => {
    setValidationHighlight({
      paymentTerm: !selectedPaymentTerm,
      paymentMethod: !paymentMethod,
      paymentAmount: !paymentAmount
    });
  }, [selectedPaymentTerm, paymentMethod, paymentAmount]);

  // Update the blinking border class to only affect the border
  const blinkingBorderClass = "relative before:absolute before:inset-0 before:border-2 before:border-red-400 before:rounded-md before:animate-pulse";

  useEffect(() => {
    const timestamp = new Date().getTime();
    setInvoiceNumber(`INV-${timestamp}`);
  }, []);

  // Calculate default amount when payment term changes
  useEffect(() => {
    if (selectedPaymentTerm && !isEditingAmount && !isCustomTerm) {
      const term = paymentTerms.find(t => t.description === selectedPaymentTerm);
      if (term) {
        const calculatedAmount = (quotationData.grandTotal * term.percentage / 100);
        setPaymentAmount(calculatedAmount.toString());
      }
    }
  }, [selectedPaymentTerm, quotationData.grandTotal, paymentTerms, isCustomTerm, isEditingAmount]);

  useEffect(() => {
    console.log('QuotationData received:', quotationData);
    console.log('Payment Terms:', quotationData.paymentTerms);
  }, [quotationData]);

  useEffect(() => {
    console.log('Selected Quote:', quotationData);
    console.log('Payment Terms Array:', quotationData.paymentTerms);
    console.log('Is Array?:', Array.isArray(quotationData.paymentTerms));
  }, [quotationData]);

  useEffect(() => {
    const loadPaymentData = async () => {
      if (quotationData && quotationData.id) {
        try {
          const quotationRef = doc(db, 'quotations', quotationData.id);
          const docSnap = await getDoc(quotationRef);
          
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.payments) {
              quotationData.payments = data.payments;
              quotationData.totalPaid = data.totalPaid || 0;
              quotationData.remainingAmount = data.remainingAmount || quotationData.grandTotal;
            }
          }
        } catch (error) {
          console.error('Error loading payment data:', error);
        }
      }
    };

    loadPaymentData();
  }, [quotationData.id]);

  useEffect(() => {
    console.log('InvoiceGenerator received quotationData:', quotationData);
    if (!quotationData?.id) {
      console.error('Warning: quotationData.id is missing!');
    }
  }, [quotationData]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
      setPaymentAmount(value);
      setIsEditingAmount(true);
    }
  };

  const calculatePaymentAmount = () => {
    return paymentAmount ? parseFloat(paymentAmount) : 0;
  };

  const getSelectedPaymentTermDetails = () => {
    const term = paymentTerms.find(t => t.description === selectedPaymentTerm);
    return term ? `${term.description} (${term.percentage}%)` : '';
  };

  const handleGenerateInvoice = () => {
    setShowSavePaymentModal(true);
  };

  const handleSavePayment = async (shouldSave) => {
    try {
      setLoading(true);
      let updatedQuotationData = quotationData;

      if (shouldSave) {
        if (!quotationData?.id) {
          throw new Error('Quotation ID is missing');
        }

        const paymentDetails = {
          description: isCustomTerm ? customPaymentTerm : getSelectedPaymentTermDetails(),
          percentage: isCustomTerm ? 0 : (paymentTerms.find(t => t.description === selectedPaymentTerm)?.percentage || 0),
          amount: calculatePaymentAmount(),
          status: 'paid',
          paidDate: new Date().toISOString(),
          paymentMethod: paymentMethod,
          invoiceNumber: invoiceNumber,
          paymentStatus: 'completed',
          timestamp: new Date().toISOString()
        };
        
        const existingPayments = quotationData.payments || [];
        const existingTotalPaid = quotationData.totalPaid || 0;
        
        updatedQuotationData = {
          ...quotationData,
          payments: [...existingPayments, paymentDetails],
          totalPaid: existingTotalPaid + calculatePaymentAmount(),
          remainingAmount: quotationData.grandTotal - (existingTotalPaid + calculatePaymentAmount()),
          lastUpdated: new Date().toISOString()
        };

        // Update Firebase
        await updateQuotation(quotationData.id, updatedQuotationData);
        
        // Update local state
        if (typeof onClose === 'function') {
          onClose(updatedQuotationData);
        }

        // Show success message
        alert('Payment information saved successfully!');
        
        // Refresh payment tracker if needed
        if (showPaymentTracker) {
          setShowPaymentTracker(false);
          setTimeout(() => setShowPaymentTracker(true), 100);
        }
      }

      // Generate and download PDF regardless of save choice
      try {
        console.log('Generating invoice PDF...', updatedQuotationData);
        const document = (
          <InvoicePDFTemplate
            invoiceData={{
              ...updatedQuotationData,
              invoiceNumber,
              invoiceDate,
              paymentMethod,
              paymentAmount: calculatePaymentAmount(),
              selectedPaymentTerm: getSelectedPaymentTermDetails(),
              companyName: quotationData.companyName,
              companyAddress: quotationData.companyAddress,
              mobileNumber: quotationData.mobileNumber,
              clientName: quotationData.clientName
            }}
          />
        );

        console.log('Creating PDF blob...');
        const blob = await pdf(document).toBlob();
        const fileName = `Invoice_${invoiceNumber}_${format(invoiceDate, 'dd-MM-yyyy')}.pdf`;

        if (isMobile) {
          try {
            const file = new File([blob], fileName, { type: 'application/pdf' });
            if (navigator.share) {
              await navigator.share({
                files: [file],
                title: 'Invoice PDF',
                text: 'Here is your invoice PDF',
              });
            } else {
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
              URL.revokeObjectURL(url);
            }
          } catch (shareError) {
            console.error('Share failed:', shareError);
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
            URL.revokeObjectURL(url);
          }
        } else {
          const url = URL.createObjectURL(blob);
          const newWindow = window.open(url, '_blank');
          if (newWindow) newWindow.opener = null;
          setTimeout(() => URL.revokeObjectURL(url), 100);
        }
      } catch (pdfError) {
        console.error('PDF Generation Error:', pdfError);
        throw new Error(`Failed to generate PDF: ${pdfError.message}`);
      }

    } catch (error) {
      console.error('Error:', error);
      alert(error.message || 'Failed to save payment information');
    } finally {
      setLoading(false);
      setShowSavePaymentModal(false);
    }
  };

  console.log('Payment Terms:', quotationData.paymentTerms);

  // Add this function to handle custom term changes
  const handleCustomTermChange = (e) => {
    setCustomPaymentTerm(e.target.value);
    // Update the payment details with custom term
    setSelectedPaymentTerm(e.target.value);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        {/* Header */}
        <div className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-700 p-4 rounded-t-lg">
          <h2 className="text-white text-xl font-bold flex items-center">
            <FileText className="mr-2" /> Generate Invoice
          </h2>
          <button
            onClick={() => setShowPaymentTracker(true)}
            className="flex items-center px-3 py-1.5 bg-white/10 hover:bg-white/20 rounded-lg text-white text-sm transition-colors"
          >
            <CreditCard className="w-4 h-4 mr-2" />
            Payment Tracker
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-4">
          {/* Company Details Preview */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center space-x-3">
              {quotationData.logo && (
                <img src={quotationData.logo} alt="Company Logo" className="w-12 h-12 rounded-lg object-contain" />
              )}
              <div>
                <h3 className="font-bold text-gray-800">{quotationData.companyName}</h3>
                <p className="text-sm text-gray-600">{quotationData.companyAddress}</p>
              </div>
            </div>
          </div>

          {/* Invoice Details */}
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-600">Invoice Number</span>
              <span className="text-sm font-bold text-gray-800">{invoiceNumber}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-600">Date</span>
              <div className="relative">
                <input
                  type="date"
                  value={format(invoiceDate, 'yyyy-MM-dd')}
                  onChange={(e) => setInvoiceDate(new Date(e.target.value))}
                  className="text-sm border rounded-md px-2 py-1 focus:ring-2 focus:ring-blue-500 outline-none"
                />
              </div>
            </div>

            {/* Payment Term Selection */}
            <div className={`flex justify-between items-center relative ${
              validationHighlight.paymentTerm ? blinkingBorderClass : ''
            } p-1`}>
              <span className="text-sm font-medium text-gray-600 z-10">Payment Term</span>
              <div className="flex flex-col space-y-2 z-10">
                <select
                  value={isCustomTerm ? 'custom' : selectedPaymentTerm}
                  onChange={(e) => {
                    if (e.target.value === 'custom') {
                      setIsCustomTerm(true);
                      setCustomPaymentTerm('');
                    } else {
                      setIsCustomTerm(false);
                      setSelectedPaymentTerm(e.target.value);
                      setIsEditingAmount(false);
                    }
                  }}
                  className="text-sm border rounded-md px-2 py-1 focus:ring-2 focus:ring-blue-500 outline-none min-w-[200px]"
                >
                  <option value="">Select Payment Term</option>
                  {Array.isArray(quotationData.paymentTerms) && quotationData.paymentTerms.map((term, index) => (
                    <option key={index} value={term.description}>
                      {term.description} ({term.percentage}%)
                    </option>
                  ))}
                  <option value="custom">Add Custom Term</option>
                </select>
                
                {isCustomTerm && (
                  <input
                    type="text"
                    value={customPaymentTerm}
                    onChange={handleCustomTermChange}
                    placeholder="Enter custom payment term"
                    className="text-sm border rounded-md px-2 py-1 focus:ring-2 focus:ring-blue-500 outline-none min-w-[200px]"
                  />
                )}
              </div>
            </div>

            {/* Editable Payment Amount */}
            <div className={`flex justify-between items-center relative ${
              validationHighlight.paymentAmount ? blinkingBorderClass : ''
            } p-1`}>
              <span className="text-sm font-medium text-gray-600 z-10">Payment Amount</span>
              <div className="relative flex items-center z-10">
                <span className="absolute left-2 text-gray-500">₹</span>
                <input
                  type="text"
                  value={paymentAmount}
                  onChange={handleAmountChange}
                  className="text-sm border rounded-md px-6 py-1 focus:ring-2 focus:ring-blue-500 outline-none w-32 text-right"
                  placeholder="Enter amount"
                />
              </div>
            </div>

            {/* Payment Method */}
            <div className={`flex justify-between items-center relative ${
              validationHighlight.paymentMethod ? blinkingBorderClass : ''
            } p-1`}>
              <span className="text-sm font-medium text-gray-600 z-10">Payment Method</span>
              <select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="text-sm border rounded-md px-2 py-1 focus:ring-2 focus:ring-blue-500 outline-none z-10"
              >
                <option value="">Select Method</option>
                <option value="cash">Cash</option>
                <option value="upi">UPI</option>
                <option value="bank">Bank Transfer</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>

            {/* Payment Amount Preview */}
            <div className="bg-blue-50 p-4 rounded-lg mt-4">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-blue-800">Total Payment Amount</span>
                <span className="text-lg font-bold text-blue-800">
                  ₹{calculatePaymentAmount().toLocaleString('en-IN')}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="border-t p-4 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 transition-colors"
          >
            Cancel
          </button>
          
          <button
            disabled={!paymentMethod || !paymentAmount}
            onClick={handleGenerateInvoice}
            className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center ${
              !paymentMethod || !paymentAmount
                ? 'bg-gray-300 text-gray-500'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            } transition-colors`}
          >
            <Download className="mr-2 h-4 w-4" />
            Generate Invoice
          </button>
        </div>
      </div>

      {showPaymentTracker && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <DollarSign className="w-6 h-6 mr-2 text-blue-600" />
                Payment Tracking
              </h2>
              <button
                onClick={() => setShowPaymentTracker(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="p-6">
              <PaymentTracker
                quotationData={{
                  ...quotationData,
                  grandTotal: quotationData.grandTotal,
                  payments: [...(quotationData.payments || [])],
                  totalPaid: quotationData.totalPaid || 0,
                  remainingAmount: quotationData.remainingAmount || quotationData.grandTotal
                }}
                paymentTerms={paymentTerms}
              />
            </div>
          </div>
        </div>
      )}

      {showSavePaymentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-bold text-gray-900 mb-4">Save Payment Information</h3>
            <p className="text-gray-600 mb-6">
              Would you like to save this payment information to the payment tracker?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => handleSavePayment(false)}
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 transition-colors"
              >
                Don't Save
              </button>
              <button
                onClick={() => handleSavePayment(true)}
                className="px-4 py-2 rounded-lg text-sm font-medium bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Save and Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceGenerator; 