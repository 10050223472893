export const getAppVersion = async () => {
  try {
    // First check if there's a pending update
    const pendingUpdate = localStorage.getItem('pendingUpdate');
    if (pendingUpdate) {
      return pendingUpdate;
    }

    const response = await fetch('/manifest.json?' + new Date().getTime(), {
      cache: 'no-cache'
    });
    const manifest = await response.json();
    return manifest.version;
  } catch (error) {
    console.error('Error getting app version:', error);
    return process.env.REACT_APP_VERSION || '1.619';
  }
};

export const checkVersionAndUpdate = async () => {
  try {
    // Add cache-busting query parameter and no-cache headers
    const response = await fetch('/manifest.json?' + new Date().getTime(), {
      cache: 'no-store',
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '1.619'
      }
    });
    
    const manifest = await response.json();
    const currentVersion = localStorage.getItem('currentVersion') || 
                         process.env.REACT_APP_VERSION || 
                         '1.619';
    
    // Compare versions properly
    const hasUpdate = compareVersions(manifest.version, currentVersion) > 0;
    
    return {
      hasUpdate,
      currentVersion,
      newVersion: manifest.version
    };
  } catch (error) {
    console.error('Error checking version:', error);
    return { hasUpdate: false, currentVersion: null, newVersion: null };
  }
};

// Add version comparison utility
export const compareVersions = (v1, v2) => {
  const normalize = v => v.split('1.619').map(Number);
  const parts1 = normalize(v1);
  const parts2 = normalize(v2);
  
  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;
    if (part1 !== part2) return part1 > part2 ? 1 : -1;
  }
  return 0;
};