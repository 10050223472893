import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, X } from 'lucide-react';

const TrialStartedPopup = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-3"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg shadow-2xl max-w-sm w-full overflow-hidden"
      >
        <div className="p-4">
          <div className="flex justify-between items-start">
            <div className="flex items-center space-x-2">
              <div className="w-10 h-10 bg-emerald-100 rounded-full flex items-center justify-center">
                <Calendar className="w-5 h-5 text-emerald-600" />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Trial Started!</h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
          
          <div className="mt-3 space-y-2">
            <p className="text-gray-600 text-sm">
              Welcome to Design Que! Your 30-day free trial has begun. Explore all our premium features and create beautiful quotations.
            </p>
            <div className="bg-emerald-50 rounded-lg p-3">
              <p className="text-sm text-emerald-700">
                Your trial will expire in <span className="font-semibold">30 days</span>
              </p>
            </div>
          </div>

          <div className="mt-4">
            <button
              onClick={onClose}
              className="w-full bg-emerald-600 text-white py-2 px-4 rounded-lg hover:bg-emerald-700 transition-colors duration-200 font-medium text-sm"
            >
              Got it
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TrialStartedPopup;
