import { db, auth } from '../Services/firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  getDoc,
  doc, 
  deleteDoc, 
  query, 
  where, 
  orderBy, 
  limit, 
  startAfter, 
  updateDoc,
  setDoc,
  writeBatch,
  onSnapshot,
  arrayUnion,
  increment
} from 'firebase/firestore';
import { useState, useCallback, useEffect } from 'react';
import ItemsList from '../components/ItemsList';
import { defaultTemplateDetails } from '../utils/defaultTemplateDetails';
import { serverTimestamp } from 'firebase/firestore';
import { deleteUser } from "firebase/auth";
import { openDB } from 'idb';

export const useFirebaseService = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleError = (error, customMessage) => {
    console.error(customMessage, error);
    setError(error.message);
    setLoading(false);
    throw error;
  };

  const getQuotations = useCallback(async (userId, lastDoc = null, pageSize = 10) => {
    setLoading(true);
    setError(null);
    try {
      let q = query(
        collection(db, 'quotations'), 
        where("userId", "==", userId),
        orderBy('createdDate', 'desc'),
        limit(pageSize)
      );
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }
      const querySnapshot = await getDocs(q);
      setLoading(false);
      return {
        quotations: querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
        isLastPage: querySnapshot.docs.length < pageSize
      };
    } catch (error) {
      handleError(error, "Error fetching quotations:");
    }
  }, []);

  const getQuotation = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      const docRef = doc(db, 'quotations', id);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error("Quotation not found");
      }
    } catch (error) {
      handleError(error, "Error fetching quotation:");
    }
  }, []);

  const addQuotation = async (quotationData) => {
    try {
      console.log('Adding new quotation:', quotationData);
      const docRef = await addDoc(collection(db, 'quotations'), quotationData);
      console.log('Document written with ID:', docRef.id);
      
      // Return the complete quotation data with the new ID
      return {
        ...quotationData,
        id: docRef.id
      };
    } catch (error) {
      console.error('Error adding quotation:', error);
      throw error;
    }
  };

  const updateQuotation = async (id, quotationData) => {
    try {
      console.log('Updating quotation:', id, quotationData);
      const docRef = doc(db, 'quotations', id);
      await updateDoc(docRef, quotationData);
      
      // Return the complete updated quotation data
      return {
        ...quotationData,
        id
      };
    } catch (error) {
      console.error('Error updating quotation:', error);
      throw error;
    }
  };

  const deleteQuotation = async (id) => {
    console.log('deleteQuotation called with id:', id);
    
    if (!id) {
      console.error('Invalid quotation ID:', id);
      throw new Error('Invalid quotation ID');
    }
    
    const quotationRef = doc(collection(db, 'quotations'), id);
    
    try {
      await deleteDoc(quotationRef);
      console.log('Quotation deleted successfully');
    } catch (error) {
      console.error('Error in deleteQuotation:', error);
      throw error;
    }
  };

  const getProducts = useCallback(async () => {
    if (!user) {
      console.log('User not authenticated');
      return [];
    }

    try {
      const productsRef = collection(db, 'products');
      const q = query(productsRef, where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  }, [user]);

  const addProduct = useCallback(async (userId, product) => {
    try {
      const productWithUserId = { ...product, userId };
      const docRef = await addDoc(collection(db, 'products'), productWithUserId);
      return { id: docRef.id, ...productWithUserId };
    } catch (error) {
      console.error("Error adding product:", error);
      throw error;
    }
  }, []);

  const updateProduct = useCallback(async (userId, id, updatedProduct) => {
    setLoading(true);
    setError(null);
    try {
      const docRef = doc(db, 'products', id);
      const updatedProductWithUserId = { ...updatedProduct, userId };
      await updateDoc(docRef, updatedProductWithUserId);
      setLoading(false);
      return { id, ...updatedProductWithUserId };
    } catch (error) {
      handleError(error, "Error updating product:");
    }
  }, []);

  const deleteProduct = useCallback(async (userId, id) => {
    setLoading(true);
    setError(null);
    try {
      const docRef = doc(db, 'products', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().userId === userId) {
        await deleteDoc(docRef);
        setLoading(false);
      } else {
        throw new Error("Product not found or user not authorized");
      }
    } catch (error) {
      handleError(error, "Error deleting product:");
    }
  }, []);

  const updateUserSettings = useCallback(async (userId, settings) => {
    try {
      const userSettingsRef = doc(db, 'userSettings', userId);
      await setDoc(userSettingsRef, settings, { merge: true });
      return true;
    } catch (error) {
      console.error("Error updating user settings:", error);
      return false;
    }
  }, [db]);

  const getUserSettings = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const userSettingsRef = doc(db, 'userSettings', userId);
      const userSettingsSnap = await getDoc(userSettingsRef);
      
      if (!userSettingsSnap.exists()) {
        // User is new, create default settings
        const defaultSettings = {
          ...defaultTemplateDetails,
          isFirstTimeUser: false,
          defaultItemsLoaded: false,
          createdAt: serverTimestamp()
        };
        await setDoc(userSettingsRef, defaultSettings);
        console.log('Default template created for new user:', userId);
        setLoading(false);
        return defaultSettings;
      }
      
      setLoading(false);
      return userSettingsSnap.data();
    } catch (error) {
      handleError(error, "Error fetching or creating user settings:");
    }
  }, []);

  const getQuotationsWithVersioning = async (userId) => {
    const quotationsRef = collection(db, 'quotations');
    const q = query(quotationsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const quotations = [];
    querySnapshot.forEach((doc) => {
      quotations.push({ id: doc.id, ...doc.data() });
    });
    return quotations;
  };

  const duplicateQuotation = useCallback(async (quotationId) => {
    setLoading(true);
    setError(null);
    try {
      const originalDoc = await getDoc(doc(db, 'quotations', quotationId));
      if (!originalDoc.exists()) {
        throw new Error('Original quotation not found');
      }

      const originalData = originalDoc.data();
      const existingQuotations = await getDocs(
        query(
          collection(db, 'quotations'),
          where('clientName', '==', originalData.clientName),
          where('propertyType', '==', originalData.propertyType)
        )
      );

      let version = 1;
      if (!existingQuotations.empty) {
        const versions = existingQuotations.docs.map(doc => doc.data().version || 1);
        version = Math.max(...versions) + 1;
      }

      const duplicatedQuotation = {
        ...originalData,
        version,
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
        propertyType: originalData.propertyType || 'N/A', // Ensure we keep the original property type
      };
      delete duplicatedQuotation.id;

      const newDocRef = await addDoc(collection(db, 'quotations'), duplicatedQuotation);
      setLoading(false);
      return { id: newDocRef.id, ...duplicatedQuotation };
    } catch (error) {
      handleError(error, "Error duplicating quotation:");
    }
  }, [db]);

  const checkDefaultItemsLoaded = useCallback(async (userId) => {
    const userSettingsRef = doc(db, 'userSettings', userId);
    const userSettingsSnap = await getDoc(userSettingsRef);
    return userSettingsSnap.exists() ? userSettingsSnap.data().defaultItemsLoaded : false;
  }, []);

  const setDefaultItemsLoadedFlag = useCallback(async (userId) => {
    const userSettingsRef = doc(db, 'userSettings', userId);
    await updateDoc(userSettingsRef, { defaultItemsLoaded: true });
  }, []);

  const isFirstTimeUser = useCallback(async (userId) => {
    const userSettingsRef = doc(db, 'userSettings', userId);
    const userSettingsSnap = await getDoc(userSettingsRef);
    return !userSettingsSnap.exists();
  }, []);

  const setFirstTimeUserFlag = useCallback(async (userId) => {
    const userSettingsRef = doc(db, 'userSettings', userId);
    await setDoc(userSettingsRef, { isFirstTimeUser: false }, { merge: true });
  }, []);

  const loadDefaultTemplate = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const userSettingsRef = doc(db, 'userSettings', userId);
      const templateToLoad = {
        ...defaultTemplateDetails,
        isFirstTimeUser: false,
        defaultItemsLoaded: true
      };
      await setDoc(userSettingsRef, templateToLoad, { merge: true });
      setLoading(false);
      console.log("Default template loaded successfully for user:", userId);
      return templateToLoad;
    } catch (error) {
      handleError(error, "Error loading default template:");
    }
  }, []);

  const initializeUserSettings = useCallback(async (userId) => {
    try {
      const isFirstTime = await isFirstTimeUser(userId);
      if (isFirstTime) {
        await loadDefaultTemplate(userId);
        await setFirstTimeUserFlag(userId);
        console.log("Default template loaded for first-time user:", userId);
        return true; // Indicates that default template was loaded
      }
      return false; // Indicates that user already had settings
    } catch (error) {
      console.error("Error initializing user settings:", error);
      throw error;
    }
  }, [isFirstTimeUser, loadDefaultTemplate, setFirstTimeUserFlag]);

  const deleteUserAccount = useCallback(async (user) => {
    setLoading(true);
    setError(null);
    try {
      const batch = writeBatch(db);
      
      // Delete user settings
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      batch.delete(userSettingsRef);

      // Delete user's quotations
      const quotationsQuery = query(collection(db, 'quotations'), where("userId", "==", user.uid));
      const quotationsSnapshot = await getDocs(quotationsQuery);
      quotationsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete user's products
      const productsQuery = query(collection(db, 'products'), where("userId", "==", user.uid));
      const productsSnapshot = await getDocs(productsQuery);
      productsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete user's shareable quotations
      const shareableQuotationsQuery = query(collection(db, 'shareableQuotations'), where("ownerId", "==", user.uid));
      const shareableQuotationsSnapshot = await getDocs(shareableQuotationsQuery);
      shareableQuotationsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();

      // Delete the user's authentication account
      await deleteUser(user);

      setLoading(false);
      return true;
    } catch (error) {
      handleError(error, "Error deleting user account:");
      return false;
    }
  }, [db]);

  const loadDefaultTemplateDetails = useCallback(async (userId) => {
    try {
      const userSettingsRef = doc(db, 'userSettings', userId);
      const userSettingsSnap = await getDoc(userSettingsRef);
      
      if (!userSettingsSnap.exists()) {
        await setDoc(userSettingsRef, defaultTemplateDetails);
        console.log("Default template details loaded for user:", userId);
        return defaultTemplateDetails;
      } else {
        return userSettingsSnap.data();
      }
    } catch (error) {
      console.error("Error loading default template details:", error);
      throw error;
    }
  }, []);

  const subscribeToQuotations = useCallback((userId, callback) => {
    const q = query(collection(db, 'quotations'), where("userId", "==", userId));
    return onSnapshot(q, (snapshot) => {
      const quotations = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(quotations);
    }, (error) => {
      console.error("Error subscribing to quotations:", error);
      setError(error.message);
    });
  }, []);

  const subscribeToProducts = useCallback((userId, callback) => {
    const q = query(collection(db, 'products'), where("userId", "==", userId));
    return onSnapshot(q, (snapshot) => {
      const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(products);
    }, (error) => {
      console.error("Error subscribing to products:", error);
      setError(error.message);
    });
  }, []);

  const generateShareableLink = useCallback(async (quotation) => {
    try {
      const shareableDoc = await addDoc(collection(db, 'shareableQuotations'), {
        originalQuotationId: quotation.id,
        ownerId: auth.currentUser.uid,
        quotationData: {
          ...quotation,
          // Explicitly include toggle states
          hideUnitPriceInPDF: quotation.hideUnitPriceInPDF || false,
          hideDiscountInPDF: quotation.hideDiscountInPDF || false,
          hideGSTInPDF: quotation.hideGSTInPDF || false,
          // Add any other toggle states here
        },
        createdAt: new Date().toISOString(),
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
      });
      
      return `${window.location.origin}/share/${shareableDoc.id}`;
    } catch (error) {
      console.error('Error generating shareable link:', error);
      throw error;
    }
  }, []);

  const getSharedQuotation = async (shareId) => {
    try {
      const sharedDocRef = doc(db, 'shareableQuotations', shareId);
      const sharedDocSnap = await getDoc(sharedDocRef);
      
      if (!sharedDocSnap.exists()) {
        throw new Error('Shared quotation not found');
      }

      const sharedData = sharedDocSnap.data();
      if (new Date(sharedData.expiresAt) < new Date()) {
        throw new Error('Shared quotation has expired');
      }

      return sharedData;
    } catch (error) {
      console.error('Error fetching shared quotation:', error);
      throw error;
    }
  };

  const duplicateSharedQuotation = async (shareId) => {
    try {
      const sharedQuotation = await getSharedQuotation(shareId);
      const newQuotation = {
        ...sharedQuotation.quotationData,
        userId: user.uid,
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString()
      };
      delete newQuotation.id;

      const addedQuote = await addQuotation(newQuotation);
      return addedQuote;
    } catch (error) {
      console.error('Error duplicating shared quotation:', error);
      throw error;
    }
  };

  const CACHE_VERSION = 1;

  const initCache = async () => {
    const db = await openDB('quotationCache', CACHE_VERSION, {
      upgrade(db) {
        db.createObjectStore('userSettings');
        db.createObjectStore('quotations');
        db.createObjectStore('products');
      },
    });
    return db;
  };

  const getCachedData = async (storeName, key) => {
    const db = await initCache();
    return db.get(storeName, key);
  };

  const setCachedData = async (storeName, key, data) => {
    const db = await initCache();
    await db.put(storeName, data, key);
  };

  const updateQuotationPayments = async (quotationId, paymentData) => {
    try {
      const quotationRef = doc(db, 'quotations', quotationId);
      await updateDoc(quotationRef, {
        payments: arrayUnion(paymentData),
        totalPaid: increment(paymentData.amount),
        remainingAmount: increment(-paymentData.amount),
        lastUpdated: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error updating payment information:', error);
      throw error;
    }
  };

  return {
    user,
    loading,
    error,
    getQuotations,
    getQuotation,
    addQuotation,
    updateQuotation,
    deleteQuotation,
    getProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    updateUserSettings,
    getUserSettings,
    getQuotationsWithVersioning,
    duplicateQuotation,
    checkDefaultItemsLoaded,
    setDefaultItemsLoadedFlag,
    isFirstTimeUser,
    setFirstTimeUserFlag,
    loadDefaultTemplate,
    initializeUserSettings,
    deleteUserAccount,
    loadDefaultTemplateDetails,
    subscribeToQuotations,
    subscribeToProducts,
    generateShareableLink,
    getSharedQuotation,
    duplicateSharedQuotation,
    updateQuotationPayments
  };
};