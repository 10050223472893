import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  initializeFirestore, 
  enableMultiTabIndexedDbPersistence,
  connectFirestoreEmulator,
  CACHE_SIZE_UNLIMITED 
} from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDBTZzkWUriMTT2vSF5yko8iPod1s0Ga9M",
  authDomain: "quote-app-21646.firebaseapp.com",
  projectId: "quote-app-21646",
  storageBucket: "quote-app-21646.appspot.com",
  messagingSenderId: "936914874624",
  appId: "1:936914874624:web:fe70583d0b6b32194443e5",
  measurementId: "G-ST6E1XM4T8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore, Auth, and Functions
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
const auth = getAuth(app);
const functions = getFunctions(app);

// Connect to emulators if in development mode
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, 'localhost', 8080);
}

// Enable persistence (optional)
enableMultiTabIndexedDbPersistence(db).catch((err) => {
  console.error('Error enabling persistence:', err);
});

// Enable phone number sign-in
auth.useDeviceLanguage();

export { db, auth, functions };