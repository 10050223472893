import React from 'react';

const PaymentStatusCard = ({ title, amount = 0, icon, variant }) => {
  const variants = {
    blue: 'from-blue-50 to-blue-100 border-blue-200 text-blue-600',
    green: 'from-green-50 to-green-100 border-green-200 text-green-600',
    orange: 'from-orange-50 to-orange-100 border-orange-200 text-orange-600'
  };

  return (
    <div
      className={`bg-gradient-to-br ${variants[variant]} p-3 sm:p-6 rounded-xl border shadow-sm 
        hover:shadow-md transition-shadow duration-200`}
    >
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs sm:text-sm font-medium opacity-80">{title}</p>
          <p className="text-lg sm:text-2xl font-bold mt-0.5 sm:mt-1">
            ₹{(amount || 0).toLocaleString('en-IN')}
          </p>
        </div>
        <div className={`bg-${variant}-500/10 p-2 sm:p-3 rounded-full`}>
          {React.cloneElement(icon, { className: `h-5 w-5 sm:h-6 sm:w-6 ${variants[variant]}` })}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusCard; 