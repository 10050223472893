import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PaymentChart = ({ totalAmount, paidAmount, pendingAmount }) => {
  const data = {
    labels: ['Paid', 'Pending'],
    datasets: [
      {
        data: [paidAmount, pendingAmount],
        backgroundColor: ['#22c55e', '#f97316'],
        borderColor: ['#16a34a', '#ea580c'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 15,
          font: {
            size: window.innerWidth < 768 ? 12 : 14
          }
        }
      },
      tooltip: {
        bodyFont: {
          size: window.innerWidth < 768 ? 12 : 14
        },
        titleFont: {
          size: window.innerWidth < 768 ? 12 : 14
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div className="bg-white p-3 sm:p-6 rounded-xl shadow-sm border border-gray-100">
      <h3 className="text-base sm:text-lg font-semibold text-gray-800 mb-2 sm:mb-4">Payment Overview</h3>
      <div className="h-48 sm:h-64">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default PaymentChart; 