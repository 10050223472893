import React, { useState, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { format, isValid, parseISO } from 'date-fns';
import { pdf } from '@react-pdf/renderer';
import { pdfThemeColors } from '../utils/pdfThemeColors';
import { useTheme } from '../hooks/useTheme';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Services/firebase';

// Register fonts
Font.register({
  family: 'NotoSans',
  fonts: [
    { src: '/NotoSans-Medium.ttf', fontWeight: 500 },
    { src: '/NotoSans-Bold.ttf', fontWeight: 700 },
  ],
});

// Helper function to format price
const formatPrice = (price) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price).replace('₹', '₹ ');
};

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  try {
    let date;
    if (typeof dateString === 'string') {
      date = parseISO(dateString);
    } else if (dateString instanceof Date) {
      date = dateString;
    } else {
      return 'Invalid Date';
    }
    return isValid(date) ? format(date, 'dd MMM yyyy') : 'Invalid Date';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

const calculateRoomTotal = (items) => {
  return items.reduce((total, item) => total + (item.totalPrice || 0), 0);
};

const QuotationPrintablePDF = ({ quotationData, theme, showLogo }) => {
  const {
    companyName,
    mobileNumber,
    logo,
    companyAddress,
    clientName,
    date,
    rooms,
    discount,
    total,
    grandTotal,
    hideUnitPrice,
    paymentTerms,
    materials,
    notes,
    isGstEnabled,
    isDiscountEnabled,
    gst,
    hideRoomTotalInPDF = localStorage.getItem('hideRoomTotalInPDF') === 'true',
    paymentTermsTitle = localStorage.getItem('paymentTermsTitle') || 'Payment Terms',
    materialSpecsTitle = localStorage.getItem('materialSpecsTitle') || 'Material Specification',
    notesTitle = localStorage.getItem('notesTitle') || 'Notes',
  } = quotationData;

  const currentTheme = pdfThemeColors[theme.toLowerCase()] || pdfThemeColors.default;

  // Use the section order from the quotation data if available, otherwise use default
  const sectionOrder = quotationData.sectionOrder || 
    JSON.parse(localStorage.getItem('sectionOrder')) || 
    ['paymentTerms', 'materials', 'notes'];

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'NotoSans',
      fontSize: 10,
      padding: 30,
      backgroundColor: '#FFFFFF',
    },
    section: {
      marginBottom: 12,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      borderBottom: 1,
      borderBottomColor: currentTheme.primary,
      paddingBottom: 10,
    },
    companyDetails: {
      width: '70%',
      paddingRight: 8,
    },
    companyName: {
      fontFamily: 'NotoSans',
      fontSize: 18,
      fontWeight: 700,
      color: currentTheme.primary,
      marginBottom: 5,
    },
    companyInfo: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 500,
      color: currentTheme.text,
      marginBottom: 2,
    },
    logoContainer: {
      width: '30%',
      height: 'auto',
      maxWidth: 130,
      maxHeight: 100,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: 8,
    },
    logo: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      borderRadius: 8,
    },
    trialVersionText: {
      fontFamily: 'NotoSans',
      fontSize: 10,
      fontWeight: 700,
      color: '#9CA3AF',
      textAlign: 'center',
      width: '100%',
    },
    clientInfoRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      padding: 4,
    },
    clientInfo: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 500,
      color: '#000000',
    },
    dateInfo: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 500,
      color: '#000000',
    },
    sectionTitle: {
      fontFamily: 'NotoSans',
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 10,
      color: currentTheme.primary,
      borderBottom: 1,
      borderBottomColor: currentTheme.border,
      paddingBottom: 5,
    },
    roomHeader: {
      backgroundColor: currentTheme.roomHeader,
      padding: 6,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 32,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      marginTop: 4,
    },
    roomHeaderContent: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 4,
    },
    roomName: {
      fontFamily: 'NotoSans',
      fontSize: 10,
      fontWeight: 700,
      color: '#1F2937',
      flex: 1,
    },
    roomValue: {
      fontFamily: 'NotoSans',
      fontSize: 9.5,
      fontWeight: 700,
      color: '#1F2937',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      padding: '4 10',
      borderRadius: 4,
      marginLeft: 8,
    },
    itemsContainer: {
      padding: 8,
      backgroundColor: currentTheme.itemsContainer || '#F9FAFB',
      marginBottom: 8,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    item: {
      marginBottom: 4,
      backgroundColor: '#FFFFFF',
      borderRadius: 6,
      padding: 4,
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemIndex: {
      width: 14,
      height: 14,
      borderRadius: 7,
      backgroundColor: currentTheme.numberCircle,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 4,
      flexShrink: 0, // Prevent shrinking to avoid cropping
    },
    itemIndexText: {
      fontFamily: 'NotoSans',
      color: '#FFFFFF',
      fontSize: 8,
      fontWeight: 700,
    },
    itemName: {
      fontFamily: 'NotoSans',
      fontSize: 7.5,
      fontWeight: 500,
      color: '#1F2937',
      flex: 4.42,
      marginRight: 4,
    },
    columnsContainer: {
      flex: 5.78,
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemDimensions: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      flex: 1.5,
      marginRight: 2,
      whiteSpace: 'nowrap',
    },
    itemDimensionValue: {
      color: '#000000',
    },
    itemDimensionUnit: {
      color: '#6B7280',
    },
    itemArea: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      flex: 1.5,
      marginRight: 2,
      whiteSpace: 'nowrap',
    },
    itemAreaLabel: {
      color: '#6B7280',
    },
    itemAreaValue: {
      color: '#000000',
    },
    itemUnitPrice: {
      fontFamily: 'NotoSans',
      fontSize: 7,
      fontWeight: 500,
      flex: 1.5,
      marginRight: 2,
      whiteSpace: 'nowrap',
    },
    itemUnitPriceLabel: {
      color: '#6B7280',
    },
    itemUnitPriceValue: {
      color: '#000000',
    },
    itemTotalPrice: {
      fontFamily: 'NotoSans',
      fontSize: 7.5,
      fontWeight: 700,
      color: '#1F2937',
      flex: 1.5,
      textAlign: 'right',
    },
    roomSection: {
      marginBottom: 8, // Increased from 4 to 8 to add more gap between room containers
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      overflow: 'hidden',
    },
    roomBottomContainer: {
      backgroundColor: currentTheme.itemBackground,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      padding: 8,
    },
    totalSection: {
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      padding: 12,
      marginTop: 8,
      marginBottom: 16,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 6,
    },
    totalLabel: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 500,
      color: '#4B5563',
    },
    totalValue: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 700,
      color: '#1F2937',
    },
    discountValue: {
      fontFamily: 'NotoSans',
      fontSize: 9,
      fontWeight: 700,
      color: '#10B981',
    },
    grandTotal: {
      marginTop: 6,
      paddingTop: 6,
      borderTopWidth: 1,
      borderTopColor: '#E5E7EB',
    },
    grandTotalLabel: {
      fontFamily: 'NotoSans',
      fontSize: 12,
      fontWeight: 700,
      color: '#1F2937',
    },
    grandTotalValue: {
      fontFamily: 'NotoSans',
      fontSize: 12,
      fontWeight: 700,
      color: '#1F2937',
    },
    subsection: {
      marginTop: 12,
    },
    subsectionTitle: {
      fontFamily: 'NotoSans',
      fontSize: 13,
      fontWeight: 700,
      color: currentTheme.primary,
      marginBottom: 6,
    },
    subsectionItem: {
      fontSize: 10,
      marginBottom: 4,
      color: currentTheme.text,
    },
    sectionContent: {
      backgroundColor: '#F9FAFB',
      borderRadius: 8,
      padding: 8,
      borderWidth: 1,
      borderColor: '#E5E7EB',
    },
    paymentTerm: {
      marginBottom: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 4,
      padding: 3,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      flexDirection: 'row', // Changed to row to align serial number and content
      alignItems: 'flex-start', // Align items to the top
    },
    paymentTermRow: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    paymentTermContent: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paymentTermDescription: {
      fontFamily: 'NotoSans',
      fontSize: 8,
      fontWeight: 500,
      color: '#374151',
      flex: 1,
    },
    paymentTermDetails: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 130, // Increased from 100 to accommodate larger gap
    },
    paymentTermPercentage: {
      fontFamily: 'NotoSans',
      fontSize: 8,
      fontWeight: 700,
      color: '#1F2937',
      width: 40,
      textAlign: 'right',
      marginRight: 28, // Increased from 4 to 28 (7 times more)
    },
    paymentTermAmount: {
      fontFamily: 'NotoSans',
      fontSize: 8,
      fontWeight: 700,
      color: '#1F2937',
      flex: 1,
    },
    serialNumber: {
      fontFamily: 'NotoSans',
      fontSize: 8,
      fontWeight: 500,
      color: '#374151',
      marginRight: 4,
      flexShrink: 0, // Prevent shrinking to avoid cropping
      width: 14, // Fixed width to align with item index
      textAlign: 'center', // Center the text within the fixed width
    },
    material: {
      marginBottom: 2,
      backgroundColor: '#FFFFFF',
      padding: 3,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      flexDirection: 'row',
      alignItems: 'flex-start', // Align items to the top
    },
    materialContent: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    materialName: {
      fontFamily: 'NotoSans',
      fontSize: 7.5,
      fontWeight: 700,
      color: '#1F2937',
      flex: 1,
    },
    materialDescription: {
      fontFamily: 'NotoSans',
      fontSize: 7.5,
      fontWeight: 500,
      color: '#374151',
      flex: 2,
    },
    note: {
      fontFamily: 'NotoSans',
      fontSize: 5.5,
      fontWeight: 500,
      color: '#374151',
      marginBottom: 2,
      backgroundColor: '#FFFFFF',
      padding: 3,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#E5E7EB',
      flexDirection: 'row', // Changed to row to align serial number and content
      alignItems: 'flex-start', // Align items to the top
    },
    noteContent: {
      fontFamily: 'NotoSans',
      fontSize: 7.5,
      fontWeight: 500,
      color: '#374151',
      flex: 1, // Allow content to take remaining space
    },
    pageBreak: {
      break: 'page',
      margin: 0,
      padding: 0,
      height: 0,
    },
  });

  const WrapperComponent = ({ children, wrapperStyle }) => {
    return (
      <View wrap style={wrapperStyle}>
        {children}
      </View>
    );
  };

  const RenderSectionItems = ({ items, renderItem, wrapperStyle }) => {
    return (
      <WrapperComponent wrapperStyle={wrapperStyle}>
        {items.map((item, index) => (
          <View key={index} wrap={false}>
            {renderItem(item, index)}
          </View>
        ))}
      </WrapperComponent>
    );
  };

  const RenderRoomItems = ({ items, renderItem }) => {
    return (
      <View>
        {items.map((item, index) => (
          <View key={index} wrap={false}>
            {renderItem(item, index)}
          </View>
        ))}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.companyDetails}>
            <Text style={styles.companyName}>{companyName}</Text>
            <Text style={styles.companyInfo}>{companyAddress}</Text>
            <Text style={styles.companyInfo}>Phone: {mobileNumber}</Text>
          </View>
          <View style={styles.logoContainer}>
            {logo && showLogo ? (
              <Image source={{ uri: logo }} style={styles.logo} />
            ) : (
              <Text style={styles.trialVersionText}>Design Quote Trial Version</Text>
            )}
          </View>
        </View>

        <View style={styles.clientInfoRow}>
          <Text style={styles.clientInfo}>Client: {clientName}</Text>
          <Text style={styles.dateInfo}>Date: {formatDate(date)}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Quotation Details</Text>
          {rooms.map((room, roomIndex) => (
            <React.Fragment key={roomIndex}>
              <View wrap={false} style={styles.roomHeader}>
                <View style={styles.roomHeaderContent}>
                  <Text style={styles.roomName}>{room.name}</Text>
                  {hideRoomTotalInPDF === false && (
                    <Text style={styles.roomValue}>
                      {formatPrice(calculateRoomTotal(room.items))}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.itemsContainer}>
                <RenderRoomItems
                  items={room.items}
                  renderItem={(item, itemIndex) => (
                    <View style={styles.item}>
                      <View style={styles.itemIndex}>
                        <Text style={styles.itemIndexText}>{itemIndex + 1}</Text>
                      </View>
                      <Text style={styles.itemName}>{item.name}</Text>
                      <View style={styles.columnsContainer}>
                        <Text style={styles.itemDimensions}>
                          {item.length !== 0 || item.height !== 0 ? (
                            <>
                              <Text style={styles.itemDimensionValue}>{item.length}'</Text>
                              <Text style={styles.itemDimensionUnit}>w X </Text>
                              <Text style={styles.itemDimensionValue}>{item.height}'</Text>
                              <Text style={styles.itemDimensionUnit}>h ft</Text>
                            </>
                          ) : (
                            '-'
                          )}
                        </Text>
                        <Text style={styles.itemArea}>
                          <Text style={styles.itemAreaLabel}>Area/No.S: </Text>
                          <Text style={styles.itemAreaValue}>{item.area}</Text>
                        </Text>
                        {!hideUnitPrice && (
                          <Text style={styles.itemUnitPrice}>
                            <Text style={styles.itemUnitPriceLabel}>Unit: </Text>
                            <Text style={styles.itemUnitPriceValue}>{formatPrice(item.boxPrice)}</Text>
                          </Text>
                        )}
                        <Text style={styles.itemTotalPrice}>{formatPrice(item.totalPrice)}</Text>
                      </View>
                    </View>
                  )}
                />
              </View>
            </React.Fragment>
          ))}
        </View>

        <View style={styles.totalSection}>
          {(!isGstEnabled && !isDiscountEnabled) ? (
            <View style={[styles.totalRow, styles.grandTotal]}>
              <Text style={styles.grandTotalLabel}>Grand Total:</Text>
              <Text style={styles.grandTotalValue}>{formatPrice(grandTotal)}</Text>
            </View>
          ) : (
            <>
              <View style={styles.totalRow}>
                <Text style={styles.totalLabel}>Total:</Text>
                <Text style={styles.totalValue}>{formatPrice(total)}</Text>
              </View>
              {isDiscountEnabled && discount !== 0 && (
                <View style={styles.totalRow}>
                  <Text style={styles.totalLabel}>Discount:</Text>
                  <Text style={styles.discountValue}>
                    {discount}% (-{formatPrice(total * discount / 100)})
                  </Text>
                </View>
              )}
              {isGstEnabled && (
                <View style={styles.totalRow}>
                  <Text style={styles.totalLabel}>GST ({gst}%):</Text>
                  <Text style={styles.totalValue}>
                    {formatPrice(((total - (total * discount / 100)) * gst / 100))}
                  </Text>
                </View>
              )}
              <View style={[styles.totalRow, styles.grandTotal]}>
                <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                <Text style={styles.grandTotalValue}>{formatPrice(grandTotal)}</Text>
              </View>
            </>
          )}
        </View>

        <View style={styles.section}>
          <View style={styles.sectionContent}>
            {sectionOrder.map(sectionId => {
              switch(sectionId) {
                case 'paymentTerms':
                  return paymentTerms.length > 0 && (
                    <View key="paymentTerms" style={styles.subsection}>
                      <Text style={styles.subsectionTitle}>{paymentTermsTitle}</Text>
                      <RenderSectionItems
                        items={paymentTerms}
                        wrapperStyle={styles.paymentTermsWrapper}
                        renderItem={(term, index) => (
                          <View style={styles.paymentTerm}>
                            <Text style={styles.serialNumber}>{index + 1}.</Text>
                            <View style={styles.paymentTermRow}>
                              <View style={styles.paymentTermContent}>
                                <Text style={styles.paymentTermDescription}>{term.description}</Text>
                                <View style={styles.paymentTermDetails}>
                                  <Text style={styles.paymentTermPercentage}>{term.percentage}%</Text>
                                  <Text style={styles.paymentTermAmount}>
                                    {formatPrice(grandTotal * term.percentage / 100)}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      />
                    </View>
                  );
                case 'materials':
                  return materials.length > 0 && (
                    <View key="materials" style={styles.subsection}>
                      <Text style={styles.subsectionTitle}>{materialSpecsTitle}</Text>
                      <RenderSectionItems
                        items={materials}
                        wrapperStyle={styles.materialsWrapper}
                        renderItem={(material, index) => (
                          <View style={styles.material}>
                            <Text style={styles.serialNumber}>{index + 1}.</Text>
                            <View style={styles.materialContent}>
                              <Text style={styles.materialName}>{material.name}:</Text>
                              <Text style={styles.materialDescription}>{material.description}</Text>
                            </View>
                          </View>
                        )}
                      />
                    </View>
                  );
                case 'notes':
                  return notes.length > 0 && (
                    <View key="notes" style={styles.subsection}>
                      <Text style={styles.subsectionTitle}>{notesTitle}</Text>
                      <RenderSectionItems
                        items={notes}
                        wrapperStyle={styles.notesWrapper}
                        renderItem={(note, index) => (
                          <View style={styles.note}>
                            <Text style={styles.serialNumber}>{index + 1}.</Text>
                            <Text style={styles.noteContent}>{note}</Text>
                          </View>
                        )}
                      />
                    </View>
                  );
                default:
                  return null;
              }
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const DownloadPrintablePDF = ({ quotationData, children, isMobile, triggerCheck }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isSubscriptionValid, setIsSubscriptionValid] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.log('No user found during subscription check for printable PDF');
          setIsSubscriptionValid(false);
          return;
        }

        console.log('Checking subscription for printable PDF user:', user.email);
        const allowedUserRef = doc(db, "allowedUsers", user.email);
        const allowedUserSnap = await getDoc(allowedUserRef);
        
        if (!allowedUserSnap.exists()) {
          console.log('No allowedUser document found for printable PDF:', user.email);
          setIsSubscriptionValid(false);
          return;
        }

        const userData = allowedUserSnap.data();
        const now = Date.now();

        if (userData.status === 'inactive') {
          console.log('User status is inactive for printable PDF:', user.email);
          setIsSubscriptionValid(false);
          return;
        }

        // Only check billing date
        if (userData.nextBillingDate) {
          const nextBilling = userData.nextBillingDate.toDate().getTime();
          const isValid = now <= nextBilling;
          console.log('Subscription check for printable PDF:', {
            email: user.email,
            currentTime: new Date(now).toLocaleString(),
            nextBillingTime: new Date(nextBilling).toLocaleString(),
            isValid,
            triggerCheck
          });
          setIsSubscriptionValid(isValid);
        } else {
          console.log('No nextBillingDate found for printable PDF user:', user.email);
          setIsSubscriptionValid(false);
        }
      } catch (error) {
        console.error('Error checking subscription for printable PDF:', error);
        setIsSubscriptionValid(false);
      }
    };

    checkSubscription();
  }, [auth.currentUser, triggerCheck]); // Only check when user changes or triggerCheck changes

  const generatePDF = async (data) => {
    const document = <QuotationPrintablePDF quotationData={data} theme={theme.name} showLogo={isSubscriptionValid} />;
    return await pdf(document).toBlob();
  };

  const handleDownload = async () => {
    setLoading(true);
    setError(null);

    try {
      console.log('Generating PDF...');
      const pdfBlob = await generatePDF(quotationData);
      console.log('PDF generated successfully');
      
      const formattedDate = format(new Date(), 'dd MMM yyyy');
      const fileName = `${quotationData.companyName}_${quotationData.clientName}_Quotation_${formattedDate}.pdf`;

      if (isMobile) {
        console.log('Attempting to share on mobile...');
        const file = new File([pdfBlob], fileName, { type: 'application/pdf' });
        if (navigator.share) {
          await navigator.share({
            files: [file],
            title: 'Printable Quotation PDF',
            text: 'Here is your printable quotation PDF',
          });
        } else {
          // Fallback for devices that don't support sharing
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');
        }
      } else {
        console.log('Downloading on desktop...');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = fileName;
        link.click();
        console.log('Download initiated');
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error generating or sharing printable PDF:', err);
      setError('Failed to generate or share printable PDF. Please try again.');
      setLoading(false);
    }
  };

  return children({ loading, error, handleDownload });
};

export default DownloadPrintablePDF;