import React, { useState, useEffect } from 'react';
import { Check, AlertCircle, DollarSign, Clock } from 'lucide-react';
import PaymentStatusCard from './PaymentStatusCard';
import PaymentHistory from './PaymentHistory';
import PaymentChart from './PaymentChart';
import { useFirebaseService } from '../../hooks/useFirebaseService';

const PaymentTracker = ({ quotationData, onUpdate }) => {
  const { updateQuotation } = useFirebaseService();
  const [payments, setPayments] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [paymentProgress, setPaymentProgress] = useState(0);

  useEffect(() => {
    if (quotationData) {
      console.log('PaymentTracker received quotationData:', quotationData);
      console.log('Payments array:', quotationData.payments);
      
      const sortedPayments = [...(quotationData.payments || [])].sort((a, b) => 
        new Date(b.timestamp || b.paidDate) - new Date(a.timestamp || a.paidDate)
      );
      
      console.log('Sorted payments:', sortedPayments);
      
      const totalAmount = parseFloat(quotationData?.grandTotal) || 0;
      const paidAmount = sortedPayments.reduce((sum, payment) => {
        const amount = parseFloat(payment?.amount) || 0;
        return sum + amount;
      }, 0);
      const pendingAmount = Math.max(0, totalAmount - paidAmount);
      
      console.log('Total amount:', totalAmount);
      console.log('Pending amount:', pendingAmount);
      
      const progress = totalAmount > 0 ? (paidAmount / totalAmount) * 100 : 0;

      setPayments(sortedPayments);
      setTotalPaid(paidAmount);
      setTotalPending(pendingAmount);
      setPaymentProgress(Math.min(100, progress));

      if (onUpdate) {
        onUpdate({
          totalPaid: paidAmount,
          remainingAmount: pendingAmount,
          payments: sortedPayments,
          progress: Math.min(100, progress)
        });
      }
    }
  }, [quotationData, onUpdate]);

  const formatPrice = (amount) => {
    return `₹${parseFloat(amount).toLocaleString('en-IN')}`;
  };

  const handleDeletePayment = async (index) => {
    if (window.confirm('Are you sure you want to delete this payment entry?')) {
      try {
        const updatedPayments = [...payments];
        const deletedPayment = updatedPayments.splice(index, 1)[0];
        
        const newTotalPaid = totalPaid - parseFloat(deletedPayment.amount);
        const newPendingAmount = parseFloat(quotationData?.grandTotal) - newTotalPaid;
        const newProgress = quotationData?.grandTotal > 0 ? (newTotalPaid / parseFloat(quotationData.grandTotal)) * 100 : 0;

        // Create updated quotation data
        const updatedQuotationData = {
          ...quotationData,
          payments: updatedPayments,
          totalPaid: newTotalPaid,
          remainingAmount: newPendingAmount,
          lastUpdated: new Date().toISOString()
        };

        // Update Firebase
        await updateQuotation(quotationData.id, updatedQuotationData);

        // Update local state
        setPayments(updatedPayments);
        setTotalPaid(newTotalPaid);
        setTotalPending(newPendingAmount);
        setPaymentProgress(Math.min(100, newProgress));

        // Update parent component
        if (onUpdate) {
          onUpdate({
            totalPaid: newTotalPaid,
            remainingAmount: newPendingAmount,
            payments: updatedPayments,
            progress: Math.min(100, newProgress)
          });
        }
      } catch (error) {
        console.error('Error deleting payment:', error);
        alert('Failed to delete payment. Please try again.');
      }
    }
  };

  return (
    <div className="space-y-4 p-3 sm:p-6 bg-gray-50 rounded-xl">
      {/* Progress Overview */}
      <div className="relative bg-white p-4 sm:p-6 rounded-xl shadow-sm border border-gray-100">
        <div 
          className="absolute top-0 left-0 h-1 bg-blue-500 transition-all duration-500 ease-in-out" 
          style={{ width: `${paymentProgress}%` }} 
        />
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-base sm:text-xl font-semibold text-gray-800">Payment Progress</h2>
          <span className="text-xs sm:text-sm font-medium text-blue-600">{paymentProgress.toFixed(1)}% Complete</span>
        </div>
        
        {/* Status Cards Grid */}
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 sm:gap-4">
          <PaymentStatusCard
            title="Total Amount"
            amount={quotationData.grandTotal}
            icon={<DollarSign />}
            variant="blue"
          />
          <PaymentStatusCard
            title="Total Paid"
            amount={totalPaid}
            icon={<Check />}
            variant="green"
          />
          <PaymentStatusCard
            title="Pending Amount"
            amount={totalPending}
            icon={<AlertCircle />}
            variant="orange"
          />
        </div>
      </div>

      {/* Payment Overview and History Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* Chart Section */}
        <div className="w-full">
          <PaymentChart
            totalAmount={quotationData.grandTotal}
            paidAmount={totalPaid}
            pendingAmount={totalPending}
          />
        </div>

        {/* History Section */}
        <div className="w-full">
          <PaymentHistory 
            payments={payments} 
            formatPrice={formatPrice} 
            onDeletePayment={handleDeletePayment}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentTracker; 