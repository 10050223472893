import React from 'react';

const LegalAndAbout = () => {
  return (
    <div className="space-y-4 p-4">
      <div className="flex flex-col items-center justify-center mb-6">
        <img 
          src="/logo512.png" 
          alt="Design Que Logo" 
          className="w-24 h-24 object-contain rounded-lg shadow-md"
        />
        <h2 className="text-xl font-semibold mt-2 text-gray-800">Design Que</h2>
      </div>
      <h2 className="text-lg font-semibold">About Us</h2>
      <p>
        Welcome to our Quotation App! We are dedicated to providing you with the best tools for managing your quotations efficiently. Our app allows you to create, edit, and manage your quotations with ease, ensuring that you can focus on what matters most in your business.
      </p>
      <p>
        Our team is committed to continuous improvement and innovation, and we value your feedback. If you have any suggestions or questions, please feel free to reach out to us.
      </p>

      <h2 className="text-lg font-semibold">Legal Information</h2>
      <p>
        By using our application, you agree to comply with our terms and conditions. Please read them carefully to understand your rights and obligations.
      </p>
      <p>
        We respect your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for details on how we collect, use, and safeguard your data.
      </p>
      <p>
        If you have any questions regarding our legal policies or practices, please contact us at <a href="mailto:info@designque.in" className="text-blue-500">info@designque.in</a>.
      </p>

      <h2 className="text-lg font-semibold">Privacy Policy</h2>
      <p>
        <strong>Effective Date:</strong> September 10, 2024
      </p>
      <p>
        At Design Que, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our Quotation App.
      </p>
      <p>
        <strong>Information We Collect:</strong> We may collect personal information such as your name, email address, and phone number when you register or use our services. We also collect usage data about how you use our app.
      </p>
      <p>
        <strong>How We Use Your Information:</strong> We use the information we collect to provide and maintain our services, notify you about changes, and improve our services.
      </p>
      <p>
        <strong>Data Security:</strong> We implement appropriate measures to protect your personal information from unauthorized access.
      </p>
      <p>
        <strong>Your Rights:</strong> You have the right to access, correct, or delete your personal information.
      </p>
      <p>
        <strong>Changes to This Privacy Policy:</strong> We may update our Privacy Policy from time to time and will notify you of any changes.
      </p>

      <p>
        <strong>Google Play Store Specific:</strong>
      </p>
      <ul className="list-disc pl-5 space-y-2">
        <li>This app does not collect or share any sensitive user data without explicit consent.</li>
        <li>All data collection practices comply with Google Play's User Data policy.</li>
        <li>Users can request data deletion through the app's settings or by contacting support.</li>
        <li>The app does not contain any third-party advertising SDKs.</li>
      </ul>

      <h2 className="text-lg font-semibold">Terms and Conditions</h2>
      <p>
        <strong>Effective Date:</strong> September 10, 2024
      </p>
      <p>
        Welcome to Design Que's Quotation App. By using our app, you agree to comply with and be bound by the following terms and conditions.
      </p>
      <p>
        <strong>Use of the App:</strong> You agree to use the app only for lawful purposes and in a manner that does not infringe the rights of others.
      </p>
      <p>
        <strong>Intellectual Property:</strong> All content and intellectual property rights in the app are owned by Design Que.
      </p>
      <p>
        <strong>Limitation of Liability:</strong> In no event shall Design Que be liable for any indirect or consequential damages arising from your use of the app.
      </p>
      <p>
        <strong>Governing Law:</strong> These Terms and Conditions shall be governed by the laws of India.
      </p>
      <p>
        <strong>Contact Us:</strong> If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@designque.in" className="text-blue-500">info@designque.in</a>.
      </p>
    </div>
  );
};

export default LegalAndAbout;
