import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { format } from 'date-fns';

// Register fonts for better typography
Font.register({
  family: 'NotoSans',
  fonts: [
    { src: '/NotoSans-Medium.ttf', fontWeight: 500 },
    { src: '/NotoSans-Bold.ttf', fontWeight: 700 },
  ],
});

const InvoicePDFTemplate = ({ invoiceData }) => {
  const paymentTermsTitle = localStorage.getItem('paymentTermsTitle') || 'Payment Terms';

  const styles = StyleSheet.create({
    page: {
      padding: 40,
      backgroundColor: '#ffffff',
      fontFamily: 'NotoSans',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 40,
      borderBottom: '2px solid #E5E7EB',
      paddingBottom: 20,
    },
    companyInfo: {
      flex: 1,
    },
    companyName: {
      fontSize: 24,
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 8,
    },
    companyDetails: {
      fontSize: 10,
      color: '#4B5563',
      marginBottom: 4,
      lineHeight: 1.4,
    },
    logoContainer: {
      width: 120,
      marginLeft: 40,
      borderRadius: 8,
      overflow: 'hidden',
    },
    logo: {
      width: '100%',
      height: 'auto',
      borderRadius: 8,
    },
    invoiceHeader: {
      backgroundColor: '#F8F9FA',
      padding: 20,
      borderRadius: 8,
      marginBottom: 30,
    },
    invoiceTitle: {
      fontSize: 20,
      fontWeight: 600,
      color: '#1F2937',
      marginBottom: 12,
    },
    infoRow: {
      flexDirection: 'row',
      marginBottom: 6,
    },
    label: {
      width: 120,
      fontSize: 10,
      color: '#6B7280',
      fontWeight: 500,
    },
    value: {
      flex: 1,
      fontSize: 10,
      color: '#1F2937',
      fontWeight: 600,
    },
    paymentSection: {
      marginTop: 30,
      backgroundColor: '#F8F9FA',
      padding: 20,
      borderRadius: 8,
    },
    paymentTitle: {
      fontSize: 14,
      fontWeight: 600,
      color: '#2C5282',
      marginBottom: 12,
    },
    paymentRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 8,
      paddingBottom: 8,
      borderBottom: '1px solid #E2E8F0',
    },
    paymentLabel: {
      fontSize: 10,
      color: '#4A5568',
      fontWeight: 500,
    },
    paymentValue: {
      fontSize: 10,
      color: '#1F2937',
      fontWeight: 600,
    },
    totalAmount: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 12,
      paddingTop: 12,
      borderTop: '2px solid #E2E8F0',
    },
    totalLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: '#2C5282',
    },
    totalValue: {
      fontSize: 12,
      fontWeight: 700,
      color: '#2C5282',
    },
    footer: {
      position: 'absolute',
      bottom: 40,
      left: 40,
      right: 40,
      textAlign: 'center',
      color: '#9CA3AF',
      fontSize: 8,
      borderTop: '1px solid #E5E7EB',
      paddingTop: 20,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.header}>
          <View style={styles.companyInfo}>
            <Text style={styles.companyName}>{invoiceData.companyName}</Text>
            <Text style={styles.companyDetails}>{invoiceData.companyAddress}</Text>
            <Text style={styles.companyDetails}>Phone: {invoiceData.mobileNumber}</Text>
          </View>
          {invoiceData.logo && (
            <View style={styles.logoContainer}>
              <Image src={invoiceData.logo} style={styles.logo} />
            </View>
          )}
        </View>

        {/* Invoice Details Section */}
        <View style={styles.invoiceHeader}>
          <Text style={styles.invoiceTitle}>Invoice</Text>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Invoice Number:</Text>
            <Text style={styles.value}>{invoiceData.invoiceNumber}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Date:</Text>
            <Text style={styles.value}>
              {format(invoiceData.invoiceDate, 'dd MMM yyyy')}
            </Text>
          </View>
        </View>

        {/* Payment Details Section */}
        <View style={styles.paymentSection}>
          <Text style={styles.paymentTitle}>{paymentTermsTitle}</Text>
          <View style={styles.paymentRow}>
            <Text style={styles.paymentLabel}>Payment Term</Text>
            <Text style={styles.paymentValue}>{invoiceData.selectedPaymentTerm}</Text>
          </View>
          <View style={styles.paymentRow}>
            <Text style={styles.paymentLabel}>Payment Method</Text>
            <Text style={styles.paymentValue}>
              {invoiceData.paymentMethod.charAt(0).toUpperCase() + 
               invoiceData.paymentMethod.slice(1)}
            </Text>
          </View>
          <View style={styles.totalAmount}>
            <Text style={styles.totalLabel}>Amount Paid</Text>
            <Text style={styles.totalValue}>
              ₹{invoiceData.paymentAmount.toLocaleString('en-IN')}
            </Text>
          </View>
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          This is a computer-generated invoice. No signature is required.
        </Text>
      </Page>
    </Document>
  );
};

export default InvoicePDFTemplate; 