import React from 'react';
import { Check, Calendar, Trash2 } from 'lucide-react';
import { format, isValid } from 'date-fns';

const PaymentHistory = ({ payments, formatPrice, onDeletePayment }) => {
  const formatDate = (dateString) => {
    // Handle different date formats
    let date;
    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === 'string') {
      date = new Date(dateString);
    } else if (dateString && dateString.toDate) {
      // Handle Firestore Timestamp
      date = dateString.toDate();
    } else {
      return 'Invalid Date';
    }

    return isValid(date) ? format(date, 'dd MMM yyyy') : 'Invalid Date';
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-3 sm:p-4 border-b border-gray-100">
        <h3 className="text-base sm:text-lg font-semibold text-gray-800">Payment History</h3>
      </div>
      <div className="divide-y divide-gray-100">
        {payments.map((payment, index) => (
          <div
            key={index}
            className="p-3 sm:p-4 hover:bg-gray-50 transition-colors duration-200"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2 sm:space-x-4">
                <div className="bg-green-100 w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center flex-shrink-0">
                  <Check className="h-4 w-4 sm:h-5 sm:w-5 text-green-600" />
                </div>
                <div>
                  <p className="text-sm sm:text-base font-medium text-gray-800">{payment.description}</p>
                  <div className="flex items-center text-xs sm:text-sm text-gray-500 mt-0.5 sm:mt-1">
                    <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                    {formatDate(payment.paidDate || payment.timestamp)}
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="text-right">
                  <p className="text-sm sm:text-base font-medium text-green-600">{formatPrice(payment.amount)}</p>
                  <p className="text-xs sm:text-sm text-gray-500">
                    {payment.paymentMethod}
                  </p>
                </div>
                <button
                  onClick={() => onDeletePayment(index)}
                  className="text-gray-400 hover:text-red-500 transition-colors duration-200"
                >
                  <Trash2 className="h-4 w-4 sm:h-5 sm:w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentHistory; 